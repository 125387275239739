import Vue from "vue";
import Component from "vue-class-component";
import { Notification } from "@/models/notification.interface";

@Component({})
export class FileCheck extends Vue {
  fileInputsAccept = {
    global: [
      ".aac",
      "audio/aac",
      ".avi",
      "video/x-msvideo",
      ".csv",
      "text/csv",
      ".doc",
      "application/msword",
      ".gif",
      "image/gif",
      ".ico",
      "image/x-icon",
      ".jpeg",
      ".jpg",
      "image/jpeg",
      ".png",
      "image/png",
      ".mpeg",
      "video/mpeg",
      ".ods",
      "application/vnd.oasis.opendocument.spreadsheet",
      ".odt",
      "application/vnd.oasis.opendocument.text",
      ".oga",
      "audio/ogg",
      ".ogv",
      "video/ogg",
      ".pdf",
      "application/pdf",
      ".ppt",
      "application/vnd.ms-powerpoint",
      ".svg",
      "image/svg+xml",
      ".swf",
      "application/x-shockwave-flash",
      ".tif",
      ".tiff",
      "image/tiff",
      ".wav",
      "audio/x-wav",
      ".weba",
      "audio/webm",
      ".webm",
      "video/webm",
      ".webp",
      "image/webp",
      ".xls",
      "application/vnd.ms-excel",
    ],
    images: [
      ".gif",
      "image/gif",
      ".ico",
      "image/x-icon",
      ".jpeg",
      ".jpg",
      "image/jpeg",
      ".svg",
      "image/svg+xml",
      ".tif",
      ".tiff",
      "image/tiff",
      ".webp",
      "image/webp",
      ".png",
      "image/png",
    ],
    vouchers: [
      ".csv",
      "text/csv",
      ".doc",
      "application/msword",
      ".jpeg",
      ".jpg",
      "image/jpeg",
      ".ods",
      "application/vnd.oasis.opendocument.spreadsheet",
      ".odt",
      "application/vnd.oasis.opendocument.text",
      ".pdf",
      "application/pdf",
      ".ppt",
      "application/vnd.ms-powerpoint",
      ".svg",
      "image/svg+xml",
      ".tif",
      ".tiff",
      "image/tiff",
      ".webp",
      "image/webp",
      ".xls",
      "application/vnd.ms-excel",
      ".png",
      "image/png",
    ],
  };

  async checkFilesType(files, accept, errorCallback) {
    if (Array.isArray(files)) {
      let typesWithErrors = "";

      files.forEach((file) => {
        let type = "";
        if (file.type !== "") {
          type = file.type;
        } else {
          type = this.getExtensionBasedOnText(file.name);
        }

        if (!(accept.indexOf(type) >= 0)) {
          if (typesWithErrors == "") {
            typesWithErrors = type;
          } else if (!typesWithErrors.includes(type)) {
            typesWithErrors = typesWithErrors + ", " + type;
          }
        }
      });

      if (typesWithErrors !== "") {
        errorCallback();
        const elements: any = document.getElementsByClassName(
          "refForErrorCallback"
        ) as HTMLCollection;

        elements.forEach((element) => {
          element.value = "";
        });
        const Error: Notification = {
          message: this.$t("Views.ff-e1", { type: typesWithErrors }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      }
    } else if (files !== null) {
      let type = "";
      if (files.type !== "") {
        type = files.type;
      } else {
        type = this.getExtensionBasedOnText(files.name);
      }

      if (!(accept.indexOf(type) >= 0)) {
        errorCallback();
        const elements: any = document.getElementsByClassName(
          "refForErrorCallback"
        ) as HTMLCollection;

        elements.forEach((element) => {
          element.value = "";
        });
        const Error: Notification = {
          message: this.$t("Views.ff-e1", { type: type }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      }
    }
  }

  private getExtensionBasedOnText(name: string) {
    const parts = name.split(".");
    return parts[parts.length - 1];
  }

  parsedAccept(accept: string[]) {
    let acceptString = "";

    accept.forEach((item) => {
      if (acceptString == "") {
        acceptString = item;
      } else {
        acceptString = acceptString + "," + item;
      }
    });

    return acceptString;
  }
}
