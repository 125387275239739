import Vue from "vue";
import SummaryService from "@/services/summary.service";
import { SummaryState } from "@/store/modules/Summary/summary.state";

export default {
  namespaced: true,
  state: {
    systemSummary: null,
  },
  getters: {
    getSystemSummary: (state: SummaryState) => state.systemSummary,
  },
  mutations: {
    setSystemSummary(state: SummaryState, systemSummary: any) {
      Vue.set(state, "systemSummary", systemSummary);
    },
  },
  actions: {
    getSystemSummary: async (context: any) => {
      try {
        const summary = (await SummaryService.getSystemSummary()).data;
        context.commit("setSystemSummary", summary);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
