import Vue from "vue";
import ApplicationService from "@/services/application.service";
import { ApplicationState } from "@/store/modules/Application/application.state";
import info from "@/utils/info";

const initialState = () => {
  return {
    phone: "",
    email: "",
    description: "",
    name: "",
    amount: "",
    lastUpdate: "",
    dialCode: "",
    iso2: "",
    formatedPhone: "",
    applications: [],
    application: null,
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getApplicationState: (state: ApplicationState) => state,
    getApplications: (state: ApplicationState) => state.applications,
    getApplication: (state: ApplicationState) => state.application,
  },
  mutations: {
    setApplications(state: ApplicationState, applications: any[]) {
      Vue.set(state, "applications", applications);
    },
    setApplication(state: ApplicationState, application: any) {
      Vue.set(state, "application", application);
    },
    setApplicationState(state: ApplicationState, application: any) {
      Vue.set(state, "phone", application.phone);
      Vue.set(state, "dialCode", application.dialCode);
      Vue.set(state, "iso2", application.iso2);
      Vue.set(state, "formatedPhone", application.formatedPhone);
      Vue.set(state, "email", application.email);
      Vue.set(state, "description", application.description);
      Vue.set(state, "name", application.name);
      Vue.set(state, "amount", application.amount);
      Vue.set(state, "lastUpdate", application.lastUpdate);
    },
    reset(state: ApplicationState, newOne: boolean) {
      if (newOne) {
        Vue.set(state, "description", "");
        Vue.set(state, "name", "");
        Vue.set(state, "amount", "");
        Vue.set(state, "lastUpdate", "");
      } else {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
          Vue.set(state, key, newState[key]);
        });
      }
    },
  },
  actions: {
    getApplicationByID: async (context: any, payload: string) => {
      try {
        const application = (
          await ApplicationService.getApplicationByID(payload)
        ).data;
        context.commit("setApplication", application);
      } catch (error) {
        throw new Error(error);
      }
    },
    getApplications: async (
      context: any,
      payload: { page: number; size: number; text: string; status: string }
    ) => {
      try {
        const applications = (await ApplicationService.getApplications(payload))
          .data;
        context.commit("setApplications", applications);
      } catch (error) {
        throw new Error(error);
      }
    },
    securitySettingsForApplication: async () => {
      const response = (await ApplicationService.getSecuritySettings()).data;
      info.setData({ token: response.token });
    },
    setApplicationInfo: async (context: any, application: any) => {
      context.commit("setApplicationState", application);
    },
    createApplication: async (context: any, payload: any) => {
      try {
        await ApplicationService.createApplication(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateApplication: async (context: any, payload: any) => {
      try {
        await ApplicationService.updateApplication(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    resetApplication: async (context: any, newOne: boolean) => {
      context.commit("reset", newOne);
    },
  },
};
