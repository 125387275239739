import Vue from "vue";
import Component from "vue-class-component";
import { Navigation as INavigation } from "@/models/navigation.interface";

@Component({})
export class Navigation extends Vue {
  navigate(route: string) {
    this.$route.fullPath !== route ? this.$router.push(route) : null;
  }

  setNavigation(navigation: INavigation) {
    this.$store.dispatch("navigation/setNavigation", {
      previousRoute: navigation.previousRoute,
      icon: navigation.icon,
      title: navigation.title,
    });
  }
}
