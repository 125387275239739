import Vue from "vue";
import FeedbackService from "@/services/feedback.service";
import { FeedbackState } from "./feedback.state";
import { Feedback } from "@/models/feedback.interface";

export default {
  namespaced: true,
  state: {
    feedbacks: [],
  },
  getters: {
    getFeedbacks: (state: FeedbackState) => state.feedbacks,
  },
  mutations: {
    setFeedbacks(state: FeedbackState, feedbacks: Feedback[]) {
      Vue.set(state, "feedbacks", feedbacks);
    },
  },
  actions: {
    getFeedbacks: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const feedbacks = (await FeedbackService.getFeedbacks(payload)).data;
        context.commit("setFeedbacks", feedbacks);
      } catch (error) {
        throw new Error(error);
      }
    },
    sendFeedback: async (context: any, payload: Feedback) => {
      try {
        await FeedbackService.sendFeedback(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    deleteFeedback: async (
      context: any,
      payload: { id: number; status: string }
    ) => {
      try {
        await FeedbackService.deleteFeedback(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
