import Vue from "vue";
import PaymentsService from "@/services/payments.service";
import { PaymentState } from "./payment.state";

export default {
  namespaced: true,
  state: {
    paymentFilesProgress: 0,
    cryptoPayments: {},
    traditionalPayments: {},
    balancePayments: {},
  },
  getters: {
    getPaymentFilesProgress: (state: PaymentState) =>
      state.paymentFilesProgress,
    getCryptoPayments: (state: PaymentState) => state.cryptoPayments,
    getTraditionalPayments: (state: PaymentState) => state.traditionalPayments,
    getBalancePayments: (state: PaymentState) => state.balancePayments,
  },
  mutations: {
    setPaymentFilesProgress(state: PaymentState, progress: number) {
      Vue.set(state, "paymentFilesProgress", progress);
    },
    setCryptoPayments(state: PaymentState, payments: any) {
      Vue.set(state, "cryptoPayments", payments);
    },
    setTraditionalPayments(state: PaymentState, payments: any) {
      Vue.set(state, "traditionalPayments", payments);
    },
    setBalancePayments(state: PaymentState, payments: any) {
      Vue.set(state, "balancePayments", payments);
    },
  },
  actions: {
    createTraditionalPayment: async (context: any, payload: any) => {
      try {
        return await PaymentsService.createTraditionalPayment(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    createCryptoPayment: async (context: any, payload: any) => {
      try {
        return await PaymentsService.createCryptoPayment(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    createBalancePayment: async (context: any, payload: any) => {
      try {
        return await PaymentsService.createBalancePayment(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    addFilesForTraditionalPayment: async (context: any, payload: FormData) => {
      try {
        await PaymentsService.addFilesForTraditionalPayment(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setPaymentFilesProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    addFilesForCryptoPayment: async (context: any, payload: FormData) => {
      try {
        await PaymentsService.addFilesForCryptoPayment(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setPaymentFilesProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    resetPaymentFilesProgress: async (context: any) => {
      context.commit("setPaymentFilesProgress", 0);
    },
    updateStatusForCryptoPayment: async (
      context: any,
      payload: { id: string; status: string }
    ) => {
      try {
        return await PaymentsService.updateStatusForCryptoPayment(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateStatusForTraditionalPayment: async (
      context: any,
      payload: { id: string; status: string }
    ) => {
      try {
        return await PaymentsService.updateStatusForTraditionalPayment(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    getCryptoPayments: async (
      context: any,
      payload: {
        page: string;
        size: number;
        payment_id: string;
      }
    ) => {
      try {
        const payments = (await PaymentsService.getCryptoPayments(payload))
          .data;
        context.commit("setCryptoPayments", payments);
      } catch (error) {
        throw new Error(error);
      }
    },
    getTraditionalPayments: async (
      context: any,
      payload: {
        page: string;
        size: number;
        payment_id: string;
      }
    ) => {
      try {
        const payments = (await PaymentsService.getTraditionalPayments(payload))
          .data;
        context.commit("setTraditionalPayments", payments);
      } catch (error) {
        throw new Error(error);
      }
    },
    getBalancePayments: async (
      context: any,
      payload: {
        page: string;
        size: number;
        payment_id: string;
      }
    ) => {
      try {
        const payments = (await PaymentsService.getBalancePayments(payload))
          .data;
        context.commit("setBalancePayments", payments);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
