import Vue from "vue";
import ProductCommentService from "@/services/product-comment.service";
import BillCommentService from "@/services/bill-comment.service";
import { CommentsState } from "./comments.state";
import { ProductComment } from "@/models/product-comment.interface";

export default {
  namespaced: true,
  state: {
    productComments: [],
    commentProgress: 0,
  },
  getters: {
    getProductComments: (state: CommentsState) => state.productComments,
    getCommentProgress: (state: CommentsState) => state.commentProgress,
  },
  mutations: {
    setProductComments(
      state: CommentsState,
      productComments: ProductComment[]
    ) {
      Vue.set(state, "productComments", productComments);
    },
    combineProductComments(state: CommentsState, productComments: any) {
      const listOfComments = state.productComments.comments;
      productComments.comments.forEach((comment: ProductComment) => {
        listOfComments.push(comment);
      });
      productComments.comments = listOfComments;
      Vue.set(state, "productComments", productComments);
    },
    setCommentProgress(state: CommentsState, commentProgress: number) {
      Vue.set(state, "commentProgress", commentProgress);
    },
  },
  actions: {
    getProductCommentsByUser: async (
      context: any,
      payload: { page: number; size: number; user_id: string; combine: boolean }
    ) => {
      try {
        const comments = (
          await ProductCommentService.getProductCommentsByUser(payload)
        ).data;

        if (payload.combine) {
          context.commit("combineProductComments", comments);
        } else {
          context.commit("setProductComments", comments);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getProductComments: async (
      context: any,
      payload: {
        page: number;
        size: number;
        product_id: string;
        combine: boolean;
      }
    ) => {
      try {
        const comments = (
          await ProductCommentService.getProductComments(payload)
        ).data;

        if (payload.combine) {
          context.commit("combineProductComments", comments);
        } else {
          context.commit("setProductComments", comments);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getAllProductComments: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const comments = (
          await ProductCommentService.getAllProductComments(payload)
        ).data;
        context.commit("setProductComments", comments);
      } catch (error) {
        throw new Error(error);
      }
    },
    addCommentToProduct: async (
      context: any,
      payload: {
        comment: { text: string; response: string | null };
        product_id: string;
      }
    ) => {
      try {
        return (await ProductCommentService.addCommentToProduct(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    updateProductCommentStatus: async (
      context: any,
      payload: { comments: number[]; status: string }
    ) => {
      try {
        await ProductCommentService.updateProductCommentStatus(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    uploadCommentFile: async (context: any, payload: any) => {
      try {
        await ProductCommentService.uploadCommentFile(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setCommentProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    resetProgress: async (context: any) => {
      try {
        context.commit("setCommentProgress", 0);
      } catch (error) {
        throw new Error(error);
      }
    },
    getBillComments: async (context: any, bill_id: string) => {
      try {
        return (await BillCommentService.getBillComments(bill_id)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    sendBillComment: async (
      context: any,
      payload: { text: string; bill_id: string; onlyText: boolean }
    ) => {
      try {
        return (
          await BillCommentService.sendBillComment(
            payload,
            (progressEvent: ProgressEvent) => {
              const { loaded, total } = progressEvent;
              const progress = Math.round((100 * loaded) / total);
              context.commit("setCommentProgress", progress);
            }
          )
        ).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    updateBillComments: async (
      context: any,
      payload: { bill_id: string; comments: string[]; status: string }
    ) => {
      try {
        await BillCommentService.updateBillComments(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    uploadBillCommentFile: async (context: any, payload: FormData) => {
      try {
        await BillCommentService.uploadBillCommentFile(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setCommentProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
