import { Detail } from "@/models/detail.interface";
import { Investment } from "@/models/investment.interface";
import { Product } from "@/models/product.interface";
import ProductsService from "@/services/products.service";
import Vue from "vue";
import { ProductsState } from "./products.state";

export default {
  namespaced: true,
  state: {
    products: [],
    product: {},
    productProgress: 0,
  },
  getters: {
    getProducts: (state: ProductsState) => state.products,
    getProduct: (state: ProductsState) => state.product,
    getProductProgress: (state: ProductsState) => state.productProgress,
  },
  mutations: {
    setProducts(state: ProductsState, products: Product[]) {
      Vue.set(state, "products", products);
    },
    setProduct(state: ProductsState, product: Product) {
      Vue.set(state, "product", product);
    },
    combineProducts(state: ProductsState, products: any) {
      const listOfProducts = state.products.products;
      products.products.forEach((product: Product) => {
        listOfProducts.push(product);
      });
      products.products = listOfProducts;
      Vue.set(state, "products", products);
    },
    setProductProgress(state: ProductsState, progress: number) {
      Vue.set(state, "productProgress", progress);
    },
  },
  actions: {
    getCreatedProductsByUser: async (
      context: any,
      payload: { page: number; size: number; user_id: string; combine: boolean }
    ) => {
      try {
        const response = (
          await ProductsService.getCreatedProductsByUser(payload)
        ).data;

        if (payload.combine) {
          context.commit("combineProducts", response);
        } else {
          context.commit("setProducts", response);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getProducts: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const response = (await ProductsService.getProducts(payload)).data;
        context.commit("setProducts", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getProductsByCategory: async (
      context: any,
      payload: { page: number; size: number; category_id: number }
    ) => {
      try {
        const response = (await ProductsService.getProductsByCategory(payload))
          .data;
        context.commit("setProducts", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getProduct: async (context: any, payload: string) => {
      try {
        const response = (await ProductsService.getProduct(payload)).data;
        context.commit("setProduct", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    createProduct: async (
      context: any,
      payload: {
        product: Product;
        investment: Investment;
        details: Detail[];
        category: { id: number };
        staff: { id: string };
      }
    ) => {
      try {
        const response = (await ProductsService.createProduct(payload)).data;
        context.commit("setProductProgress", 100);
        return response;
      } catch (error) {
        throw new Error(error);
      }
    },
    createProductFiles: async (context: any, payload: FormData) => {
      try {
        await ProductsService.createProductFiles(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setProductProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    createDetailFiles: async (context: any, payload: FormData) => {
      try {
        await ProductsService.createDetailFiles(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setProductProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    updateProduct: async (
      context: any,
      payload: {
        product: Product;
        investment: Investment;
        details: Detail[];
        category: { id: number };
      }
    ) => {
      try {
        await ProductsService.updateProduct(payload);
        context.commit("setProductProgress", 100);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateProductStatus: async (
      context: any,
      payload: { id: string; status: string }
    ) => {
      try {
        await ProductsService.updateProductStatus(payload.id, payload.status);
        context.commit("setProductProgress", 100);
      } catch (error) {
        throw new Error(error);
      }
    },
    resetProductProgress: async (context: any) => {
      context.commit("setProductProgress", 0);
    },
  },
};
