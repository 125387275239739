import { Detail } from "@/models/detail.interface";
import DetailsService from "@/services/details.service";
import Vue from "vue";
import { DetailsState } from "./details.state";

export default {
  namespaced: true,
  state: {
    details: [],
  },
  getters: {
    getDetails: (state: DetailsState) => state.details,
  },
  mutations: {
    setDetails(state: DetailsState, details: Detail[]) {
      Vue.set(state, "details", details);
    },
  },
  actions: {
    getDetails: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const response = (await DetailsService.getDetails(payload)).data;
        context.commit("setDetails", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getAssignableDetails: async () => {
      try {
        return (await DetailsService.getAssignableDetails()).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    createDetail: async (context: any, payload: { detail: Detail }) => {
      try {
        return (await DetailsService.createDetail(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    updateDetail: async (context: any, payload: { detail: Detail }) => {
      try {
        await DetailsService.updateDetail(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateDetailStatus: async (
      context: any,
      payload: { id: number; status: string }
    ) => {
      try {
        await DetailsService.updateDetailStatus(payload.id, payload.status);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
