import { Term } from "@/models/term.interface";
import TermsService from "@/services/terms.service";
import Vue from "vue";
import { TermsState } from "./terms.state";

export default {
  namespaced: true,
  state: {
    terms: [],
  },
  getters: {
    getTerms: (state: TermsState) => state.terms,
  },
  mutations: {
    setTerms(state: TermsState, terms: Term[]) {
      Vue.set(state, "terms", terms);
    },
  },
  actions: {
    getTerms: async (
      context: any,
      payload: { page: number; size: number; type: string }
    ) => {
      try {
        const response = (await TermsService.getTerms(payload)).data;
        context.commit("setTerms", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getAssignableTerms: async () => {
      try {
        return (await TermsService.getAssignableTerms()).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    createTerm: async (context: any, payload: { term: Term }) => {
      try {
        return (await TermsService.createTerm(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    updateTerm: async (context: any, payload: { term: Term }) => {
      try {
        return (await TermsService.updateTerm(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    updateTermStatus: async (
      context: any,
      payload: { id: number; status: string }
    ) => {
      try {
        await TermsService.updateTermStatus(payload.id, payload.status);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
