import Vue from "vue";
import PaymentPlatformsService from "@/services/platforms.service";
import { PaymentPlatformState } from "./platform.state";

export default {
  namespaced: true,
  state: {
    currentPaymentPlatform: {},
    paymentPlatforms: [],
    paymentPlatformProgress: 0,
  },
  getters: {
    getCurrentPaymentPlatform: (state: PaymentPlatformState) =>
      state.currentPaymentPlatform,
    getAllPaymentPlatforms: (state: PaymentPlatformState) =>
      state.paymentPlatforms,
    getPaymentPlatformProgress: (state: PaymentPlatformState) =>
      state.paymentPlatformProgress,
  },
  mutations: {
    setCurrentPaymentPlatform(state: any, currentPaymentPlatform: any) {
      Vue.set(state, "currentPaymentPlatform", currentPaymentPlatform);
    },
    setPaymentPlatforms(state: any, paymentPlatforms: any[]) {
      Vue.set(state, "paymentPlatforms", paymentPlatforms);
    },
    setPaymentPlatformProgress(state: PaymentPlatformState, progress: number) {
      Vue.set(state, "paymentPlatformProgress", progress);
    },
    combinePaymentPlatform(state: PaymentPlatformState, paymentPlatforms: any) {
      const listOfPaymentPlatforms: { platforms: any; currentPage: any } | any =
        state.paymentPlatforms;
      listOfPaymentPlatforms.currentPage = paymentPlatforms.currentPage;
      paymentPlatforms.forEach((platform: any) => {
        listOfPaymentPlatforms.platforms.push(platform);
      });
      Vue.set(state, "paymentPlatforms", listOfPaymentPlatforms);
    },
  },
  actions: {
    getAllPaymentPlatforms: async (
      context: any,
      payload: {
        page: number;
        size: number;
        text: string;
      }
    ) => {
      try {
        const paymentPlatforms = (
          await PaymentPlatformsService.getAllPaymentPlatforms(payload)
        ).data;
        context.commit("setPaymentPlatforms", paymentPlatforms);
      } catch (error) {
        throw new Error(error);
      }
    },
    getMorePaymentPlarformsForClient: async (
      context: any,
      payload: {
        page: number;
        size: number;
        text: string;
      }
    ) => {
      try {
        const paymentPlatforms = (
          await PaymentPlatformsService.getAllPaymentPlatforms(payload)
        ).data.platforms;
        context.commit("combinePaymentPlatform", paymentPlatforms);
      } catch (error) {
        throw new Error(error);
      }
    },
    getPaymentPlatformById: async (context: any, payload: number) => {
      try {
        const currentPaymentPlatform = (
          await PaymentPlatformsService.getPaymentPlatformById(payload)
        ).data;
        context.commit("setCurrentPaymentPlatform", currentPaymentPlatform);
      } catch (error) {
        throw new Error(error);
      }
    },
    uploadPaymentPlatformLogo: async (context: any, payload: FormData) => {
      try {
        await PaymentPlatformsService.uploadPaymentPlatformLogo(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setPaymentPlatformProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    updatePaymentPlatform: async (
      context: any,
      payload: { data: FormData; id: number }
    ) => {
      try {
        await PaymentPlatformsService.updatePaymentPlatform(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setPaymentPlatformProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    addPaymentPlatform: async (context: any, payload: any) => {
      try {
        return (await PaymentPlatformsService.addPaymentPlatform(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    updatePaymentPlatformStatus: async (
      context: any,
      payload: { id: number; status: string }
    ) => {
      try {
        await PaymentPlatformsService.updatePaymentPlatformStatus(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    resetPaymentPlatformProgress: async (context: any) => {
      context.commit("setPaymentPlatformProgress", 0);
    },
  },
};
