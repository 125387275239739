import { FAQ } from "@/models/faq.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getFAQ(payload: { page: number; size: number; text: string }) {
    return API_URL.get(
      `/faqs?page=${payload.page}&size=${payload.size}&text=${payload.text}`
    );
  },
  getFAQToClientSide() {
    return API_URL.get(`/faqs/client`);
  },
  createFAQ(payload: FAQ) {
    return API_URL.post(`/faqs`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  deleteFAQ(id: number) {
    return API_URL.delete(`/faqs/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
