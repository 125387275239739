import { Privilege } from "@/models/privilege.interface";
import Vue from "vue";
import store from "@/store";
type permsObject = {
  actions: "C" | "R" | "U" | "D" | string[];
  entities: string[];
};
type permissions = string[] | permsObject;

const mapAction = (action: string) => action.charAt(0).toUpperCase();

export function checkPerms(permissions: permissions) {
  const user_privileges: Privilege[] =
    store.getters["authentication/getPrivileges"];
  const privileges: string[] = Array.isArray(permissions)
    ? permissions
    : permissions.entities;
  const types = !Array.isArray(permissions)
    ? Array.isArray(permissions.actions)
      ? permissions.actions.map(mapAction)
      : mapAction(permissions.actions)
    : ["R"];
  let has_permission = false;
  user_privileges.forEach((user_privilege) => {
    privileges.forEach((privilege) => {
      if (
        user_privilege.name == Vue.prototype.$constants.PRIVILEGES[privilege] &&
        types.includes(user_privilege.type)
      ) {
        has_permission = true;
      }
    });
  });
  return has_permission;
}
