import Vue from "vue";
import SettingsService from "@/services/settings.service";
import { SettingsState } from "@/store/modules/Settings/settings.state";
import { Status } from "@/models/status.interface";
import info from "@/utils/info";
import { InformationPage } from "@/models/information-page.interface";

export default {
  namespaced: true,
  state: {
    firstStepsRegistered: false,
    logoProgress: 0,
    carouselProgress: 0,
    currentInformationPage: {},
    messageOfTheDay: {},
    messageOfTheDayState: true,
    sessionName: "",
  },
  getters: {
    getFirstStepsRegistered: (state: SettingsState) =>
      state.firstStepsRegistered,
    getLogoProgress: (state: SettingsState) => state.logoProgress,
    getCarouselProgress: (state: SettingsState) => state.carouselProgress,
    getCurrentInformationPage: (state: SettingsState) =>
      state.currentInformationPage,
    getMessageOfTheDay: (state: SettingsState) => state.messageOfTheDay,
    getMessageOfTheDayState: (state: SettingsState) =>
      state.messageOfTheDayState,
    getSessionName: (state: SettingsState) => state.sessionName,
  },
  mutations: {
    setSessionName(state: SettingsState, name: string) {
      Vue.set(state, "sessionName", name);
    },
    setFirstStepsRegistered(state: SettingsState, checked: boolean) {
      Vue.set(state, "firstStepsRegistered", checked);
    },
    setLogoProgress(state: SettingsState, progress: number) {
      Vue.set(state, "logoProgress", progress);
    },
    setCarouselProgress(state: SettingsState, progress: number) {
      Vue.set(state, "carouselProgress", progress);
    },
    setCurrentInformationPage(state: SettingsState, page: InformationPage) {
      Vue.set(state, "currentInformationPage", page);
    },
    setMessageOfTheDay(state: SettingsState, messageOfTheDay: InformationPage) {
      if (
        messageOfTheDay.value !== state.messageOfTheDay.value &&
        !state.messageOfTheDayState
      ) {
        Vue.set(state, "messageOfTheDayState", true);
      }

      Vue.set(state, "messageOfTheDay", messageOfTheDay);
    },
    setMessageOfTheDayState(
      state: SettingsState,
      messageOfTheDayState: boolean
    ) {
      Vue.set(state, "messageOfTheDayState", messageOfTheDayState);
    },
  },
  actions: {
    getFirstStepsRegisteredByInitialData: async (context: any, data: any) => {
      try {
        context.commit("setSessionName", data.sessionName);

        if (data.name == Vue.prototype.$constants.STATUS.ACTIVE) {
          const token = data.token;
          info.setData({ token: token });
          context.commit("setFirstStepsRegistered", false);
        } else {
          context.commit("setFirstStepsRegistered", true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getFirstStepsRegistered: async (context: any) => {
      try {
        const response = (await SettingsService.verifyFirstStepsStatus()).data;
        const status: Status = response;

        context.commit("setSessionName", response.sessionName);

        if (status.name == Vue.prototype.$constants.STATUS.ACTIVE) {
          const token = response.token;
          info.setData({ token: token });
          context.commit("setFirstStepsRegistered", false);
        } else {
          context.commit("setFirstStepsRegistered", true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getSecuritySession: async () => {
      try {
        await SettingsService.getSecuritySession();
      } catch (error) {
        throw new Error(error);
      }
    },
    getCurrentInformationPage: async (context: any, payload: string) => {
      try {
        const response = (await SettingsService.getConfigurationPage(payload))
          .data;
        context.commit("setCurrentInformationPage", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getMessageOfTheDay: async (context: any, payload: string) => {
      try {
        const response = (await SettingsService.getConfigurationPage(payload))
          .data;
        context.commit("setMessageOfTheDay", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    setMessageOfTheDayState: async (context: any, payload: boolean) => {
      context.commit("setMessageOfTheDayState", payload);
    },
    updateCurrentInformationPage: async (
      context: any,
      payload: InformationPage
    ) => {
      try {
        await SettingsService.updateConfigurationPage(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    setFirstSteps: async (context: any, payload: any) => {
      try {
        await SettingsService.setFirstSteps(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setLogoProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    updateCompanyInfo: async (context: any, payload: any) => {
      try {
        await SettingsService.updateCompanyInfo(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setLogoProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    setCarousel: async (context: any, payload: any) => {
      try {
        return await SettingsService.setSystemCarousel(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setCarouselProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    clearProgress: async (context: any) => {
      try {
        context.commit("setCarouselProgress", 0);
        context.commit("setLogoProgress", 0);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
