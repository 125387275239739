import Component, { mixins } from "vue-class-component";
import { Navigation } from "./navigation";

@Component({})
export class DeviceCheck extends mixins(Navigation) {
  isMobile() {
    const device = this.$device;
    return device.mobile || device.android || device.ios;
  }

  async assertMobileContext(redirect = false) {
    if (this.isMobile()) {
      const frontContext = this.$constants.CONTEXT.FRONTOFFICE;
      const currentContext = this.$store.getters["authentication/getContext"];
      if (currentContext && frontContext != currentContext) {
        await this.$store.dispatch("authentication/setContext", frontContext);

        if (redirect) {
          this.navigate("/home");
        }
        return true;
      }
    }
  }
}
