import { SocialNetwork } from "@/models/social-network.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";
import Vue from "vue";

export default {
  getSocialNetworks() {
    return API_URL.get(
      `/social-networks?status=${Vue.prototype.$constants.STATUS.ACTIVE}&all=true`
    );
  },
  getSocialNetworksToAdmin(payload: {
    page: number;
    size: number;
    text: string;
  }) {
    return API_URL.get(
      `/social-networks/staff?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  createSocialNetwork(payload: SocialNetwork) {
    return API_URL.post(`/social-networks`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateSocialNetwork(payload: SocialNetwork) {
    return API_URL.put(
      `/social-networks/update/${payload.id}`,
      { social_network: payload },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateSocialNetworkStatus(id: number, status: string) {
    return API_URL.put(
      `/social-networks/status/${id}`,
      { status: status },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
