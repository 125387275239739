import Vue from "vue";
import { NavigationState } from "./navigation.state";

export default {
  namespaced: true,
  state: {
    previousRoute: "",
    icon: "",
    title: "",
  },
  getters: {
    getNavigation: (state: NavigationState) => state,
  },
  mutations: {
    setNavigation(state: NavigationState, navigation: NavigationState) {
      Vue.set(state, "previousRoute", navigation.previousRoute);
      Vue.set(state, "icon", navigation.icon);
      Vue.set(state, "title", navigation.title);
    },
  },
  actions: {
    setNavigation: async (context: any, payload: NavigationState) => {
      context.commit("setNavigation", payload);
    },
  },
};
