import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getAllWithdrawalsByUser(payload: {
    page: number;
    size: number;
    withdrawal_id: string;
  }) {
    return API_URL.get(
      `/withdrawals/user?page=${payload.page}&size=${payload.size}&withdrawal_id=${payload.withdrawal_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getWithdrawals(payload: {
    page: number;
    size: number;
    withdrawal_id: string;
  }) {
    return API_URL.get(
      `/withdrawals?page=${payload.page}&size=${payload.size}&withdrawal_id=${payload.withdrawal_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getVouchers(payload: { page: number; size: number }) {
    return API_URL.get(
      `/withdrawals/vouchers?page=${payload.page}&size=${payload.size}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  requestWithdrawal(amount: number) {
    return API_URL.post(
      `/withdrawals/request`,
      {
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  exceptionalWithdrawalRequest(payload: any) {
    return API_URL.post(`/withdrawals/exceptional-request`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  usersForExceptionalWithdrawalRequest(search: string) {
    return API_URL.get(
      `/withdrawals/users-for-exceptional-request?text=${search}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  uploadVoucher(payload: FormData, progress) {
    return API_URL.post(`/files/withdrawal-voucher`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  addWithdrawalVoucher(payload: {
    withdrawal_id: string;
    amount: number;
    voucher_file: number;
    platform: number;
  }) {
    return API_URL.post(
      `/withdrawals/${payload.withdrawal_id}/voucher`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
