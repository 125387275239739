import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getStyles() {
    return API_URL.get(`/styles`);
  },
  setStyles(payload) {
    return API_URL.put(`/styles/update`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
