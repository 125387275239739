import { Role } from "@/models/role.interface";
import { Privilege } from "@/models/privilege.interface";
import RolesService from "@/services/roles.service";
import Vue from "vue";
import { RolesState } from "./roles.state";

export default {
  namespaced: true,
  state: {
    roles: [],
    privileges: [],
    role: {},
  },
  getters: {
    getRoles: (state: RolesState) => state.roles,
    getRole: (state: RolesState) => state.role,
    getPrivileges: (state: RolesState) => state.privileges,
  },
  mutations: {
    setRoles(state: RolesState, roles: Role[]) {
      Vue.set(state, "roles", roles);
    },
    setRole(state: RolesState, role: Role) {
      Vue.set(state, "role", role);
    },
    setPrivileges(state: RolesState, privileges: Privilege[]) {
      Vue.set(state, "privileges", privileges);
    },
  },
  actions: {
    getAssignableRoles: async (context: any) => {
      try {
        const response = (await RolesService.getAssignableRoles()).data;
        context.commit("setRoles", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getRoles: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const response = (await RolesService.getRoles(payload)).data;
        context.commit("setRoles", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getRoleById: async (context: any, payload: number) => {
      try {
        const response = (await RolesService.getRoleById(payload)).data;
        context.commit("setRole", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getPrivileges: async (context: any) => {
      try {
        const response = (await RolesService.getPrivileges()).data;
        context.commit("setPrivileges", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    createRole: async (
      context: any,
      payload: { role: Role; privileges: Privilege[] }
    ) => {
      try {
        await RolesService.createRole(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateRole: async (
      context: any,
      payload: { role: Role; privileges: Privilege[] }
    ) => {
      try {
        await RolesService.updateRole(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateRoleStatus: async (
      context: any,
      payload: { id: number; status: string }
    ) => {
      try {
        await RolesService.updateRoleStatus(payload.id, payload.status);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
