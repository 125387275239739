import Vue from "vue";
import FAQService from "@/services/faq.service";
import { FAQState } from "@/store/modules/FAQ/faq.state";
import { FAQ } from "@/models/faq.interface";

export default {
  namespaced: true,
  state: {
    faq: [],
  },
  getters: {
    getFAQ: (state: FAQState) => state.faq,
  },
  mutations: {
    setFAQ(state: FAQState, faq: FAQ[]) {
      Vue.set(state, "faq", faq);
    },
  },
  actions: {
    getFAQ: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const faq = (await FAQService.getFAQ(payload)).data;
        context.commit("setFAQ", faq);
      } catch (error) {
        throw new Error(error);
      }
    },
    getFAQToClientSide: async (context: any) => {
      try {
        const faq = (await FAQService.getFAQToClientSide()).data;
        context.commit("setFAQ", faq);
      } catch (error) {
        throw new Error(error);
      }
    },
    createFAQ: async (context: any, payload: FAQ) => {
      try {
        await FAQService.createFAQ(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    deleteFAQ: async (context: any, payload: number) => {
      try {
        await FAQService.deleteFAQ(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
