import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getProductCommentsByUser(payload: {
    page: number;
    size: number;
    user_id: string;
  }) {
    return API_URL.get(
      `/comments/products/user/${payload.user_id}?page=${payload.page}&size=${payload.size}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getProductComments(payload: {
    page: number;
    size: number;
    product_id: string;
  }) {
    return API_URL.get(
      `/comments/product/${payload.product_id}?page=${payload.page}&size=${payload.size}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getAllProductComments(payload: { page: number; size: number; text: string }) {
    return API_URL.get(
      `/comments/products/all?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  addCommentToProduct(payload: {
    comment: { text: string; response: string | null };
    product_id: string;
  }) {
    return API_URL.post(
      `/comments/product`,
      {
        comment: payload.comment,
        product: {
          id: payload.product_id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateProductCommentStatus(payload: { comments: number[]; status: string }) {
    return API_URL.put(
      `/comments/product/status`,
      { status: payload.status, comments: payload.comments },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  uploadCommentFile(payload, progress) {
    return API_URL.post(`/files/product/comment`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
};
