import { Category } from "@/models/category.interface";
import CategoryService from "@/services/categories.service";
import Vue from "vue";
import { CategoriesState } from "./categories.state";

export default {
  namespaced: true,
  state: {
    categories: [],
    categoryProgress: 0,
  },
  getters: {
    getCategories: (state: CategoriesState) => state.categories,
    getCategoryProgress: (state: CategoriesState) => state.categoryProgress,
  },
  mutations: {
    setCategories(state: CategoriesState, categories: Category[]) {
      Vue.set(state, "categories", categories);
    },
    setCategoryProgress(state: CategoriesState, progress: number) {
      Vue.set(state, "categoryProgress", progress);
    },
  },
  actions: {
    getCategories: async (context: any) => {
      try {
        const response = (await CategoryService.getCategories()).data;
        context.commit("setCategories", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getAssignableCategories: async () => {
      try {
        return (await CategoryService.getAssignableCategories()).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    createCategory: async (context: any, payload: FormData) => {
      try {
        await CategoryService.createCategory(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setCategoryProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    updateCategory: async (
      context: any,
      payload: { data: FormData; id: number }
    ) => {
      try {
        await CategoryService.updateCategory(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setCategoryProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    updateCategoryStatus: async (
      context: any,
      payload: { id: number; status: string }
    ) => {
      try {
        await CategoryService.updateCategoryStatus(payload.id, payload.status);
      } catch (error) {
        throw new Error(error);
      }
    },
    resetCategoryProgress: async (context: any) => {
      context.commit("setCategoryProgress", 0);
    },
  },
};
