import Vue from "vue";
import { IconsState } from "@/store/modules/Icons/icons.state";
import IconsService from "@/services/icons.service";

export default {
  namespaced: true,
  state: {
    icons: [],
  },
  getters: {
    getIcons: (state: IconsState) => state.icons,
  },
  mutations: {
    setIcons(state: IconsState, icons: any[]) {
      Vue.set(state, "icons", icons);
    },
  },
  actions: {
    getIcons: async (context: any) => {
      try {
        const icons = (await IconsService.getIcons()).data;
        context.commit("setIcons", icons);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
