import Vue from "vue";
import NewsletterService from "@/services/newsletter.service";
import { NewsletterState } from "./newsletter.state";
import { Newsletter } from "@/models/newsletter.interface";

export default {
  namespaced: true,
  state: {
    newsletters: [],
    subscriptors: [],
    campaignProgress: 0,
  },
  getters: {
    getNewsletters: (state: NewsletterState) => state.newsletters,
    getSubscriptors: (state: NewsletterState) => state.subscriptors,
    getCampaignProgress: (state: NewsletterState) => state.campaignProgress,
  },
  mutations: {
    setNewsletters(state: NewsletterState, newsletters: Newsletter[]) {
      Vue.set(state, "newsletters", newsletters);
    },
    setSubscriptors(state: NewsletterState, subscriptors: Newsletter[]) {
      Vue.set(state, "subscriptors", subscriptors);
    },
    setCampaignProgress(state: NewsletterState, progress: number) {
      Vue.set(state, "campaignProgress", progress);
    },
  },
  actions: {
    getNewsletters: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const newsletters = (await NewsletterService.getNewsletter(payload))
          .data;
        context.commit("setNewsletters", newsletters);
      } catch (error) {
        throw new Error(error);
      }
    },
    getAssignableSubscriptors: async (context: any) => {
      try {
        const subscriptors = (
          await NewsletterService.getAssignableSubscriptors()
        ).data;
        context.commit("setSubscriptors", subscriptors);
      } catch (error) {
        throw new Error(error);
      }
    },
    subscribe: async (context: any, payload: Newsletter) => {
      try {
        return (await NewsletterService.subscribe(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    removeSubscriber: async (context: any, payload: number) => {
      try {
        await NewsletterService.removeSubscriber(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    unsubscribe: async (context: any, payload: any) => {
      try {
        await NewsletterService.unsubscribe(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    sendCampaign: async (context: any, payload: any) => {
      try {
        await NewsletterService.sendCampaign(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setCampaignProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    clearProgress: async (context: any) => {
      try {
        context.commit("setCampaignProgress", 0);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
