import Vue from "vue";
import Component from "vue-class-component";
import { Notification } from "@/models/notification.interface";

@Component({})
export class FormValidations extends Vue {
  generalRules = [
    (v: any) =>
      (v && isNaN(v) && v.length > 0) ||
      (!isNaN(v) && v > 0) ||
      v == null ||
      this.$t("Rules.empty"),
  ];
  autocompleteRules = [(v: any) => (!!v && !!v.id) || this.$t("Rules.empty")];
  emailRules = [
    (v: any) => (v && v.length > 0) || this.$t("Rules.empty"),
    (v: any) => /.+@.+\..+/.test(v) || this.$t("Rules.email"),
  ];
  numberRules = [
    (v: any) => /^[0-9]*[1-9][0-9]*$/.test(v) || this.$t("Rules.number"),
  ];
  positiveRules = [(v: number) => v > 0 || this.$t("Rules.positive")];
  percentageRules = [
    (v: number) => (v > 0 && v <= 100) || this.$t("Rules.percentage"),
  ];
  phoneRules = [
    (v: any) => /^[0-9]*[1-9][0-9]*$/.test(v) || this.$t("Rules.number"),
    (v: any) => (v && v.length > 0) || this.$t("Rules.empty"),
  ];
  filesRules = [(value: any) => !!value || this.$t("Rules.file")];
  multipleFilesRules = [
    (value: any) => value.length > 0 || this.$t("Rules.files"),
  ];

  commentFile = [
    (file) => !file || file.size < 1000000 || this.$t("Views.fv-1"),
  ];

  resetFormValidations(forms: HTMLFormElement[]) {
    forms.forEach((form) => {
      form.resetValidation();
    });
  }

  copyToClipboard(id: string, message: string) {
    const copyText: any = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");

    const Success: Notification = {
      message: message,
      timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
      top: true,
      bottom: false,
      left: false,
      right: false,
      currentPath: this.$route.fullPath,
      error: false,
    };

    this.$store.dispatch("notifications/showNotification", Success);
  }

  currencyInputOptions = {
    locale: process.env.VUE_APP_CURRENCY_LOCALE,
    prefix: process.env.VUE_APP_CURRENCY_PREFIX,
    suffix: process.env.VUE_APP_CURRENCY_SUFFIX,
    length: process.env.VUE_APP_CURRENCY_LENGTH,
    precision: process.env.VUE_APP_CURRENCY_PRECISION,
  };
}
