import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  createBill(payload: { id: string; quantity: number }) {
    return API_URL.post(
      `/bills`,
      {
        product: {
          id: payload.id,
          quantity: payload.quantity,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getSingleBill(bill_id: string) {
    return API_URL.get(`/bills/${bill_id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getBillForChat(bill_id: string) {
    return API_URL.get(`/bills/for-chat/${bill_id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getUserBills(payload: {
    page: string;
    size: number;
    user_id: string;
    operation: string;
    bill_id: string;
  }) {
    return API_URL.get(
      `/bills?client=${payload.user_id}&size=${payload.size}&page=${payload.page}&operation=${payload.operation}&bill_id=${payload.bill_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getBills(payload: {
    page: string;
    size: number;
    operation: string;
    bill_id: string;
  }) {
    return API_URL.get(
      `/bills?size=${payload.size}&page=${payload.page}&operation=${payload.operation}&bill_id=${payload.bill_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateBillStatus(payload: { id: string; status: string }) {
    return API_URL.put(
      `/bills/status/${payload.id}`,
      {
        status: payload.status,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
