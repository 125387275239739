























import Component, { mixins } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class LocaleSwitcher extends mixins(StyleCheck) {
  @Prop({ default: "primary", required: false }) reference!: string;
  @Prop({ default: true, required: false }) dark!: boolean;
  @Prop({ default: false, required: false }) black!: boolean;
  @Prop({ default: true }) showLocaleSwitcher!: boolean;
  @Prop({ default: false })
  small!: boolean;
  @Prop({
    default: () => {
      return {};
    },
  })
  styles!: Record<string, unknown>;
  languages: Array<{ text: string; value: string }> = [];
  language = "en";

  private get currentLanguage(): string {
    return this.$store.getters["language/getLanguage"];
  }
  private created(): void {
    const language = this.currentLanguage;

    if (language !== null) {
      this.$i18n.locale = language;
      this.language = language;
      this.setItemsForSelect();
    } else {
      this.setLanguage();
    }
  }

  private mounted() {
    this.$root.$on("languageUpdated", (lang: string) => {
      this.updateLanguage(lang);
    });
  }

  @Watch("language")
  private handlerLanguage(value: string) {
    this.$i18n.locale = value;
    this.language = value;
    this.setItemsForSelect();
    this.$store.dispatch("language/setLanguage", this.language);
    this.$emit("resetForms");
    this.$root.$emit("languageUpdated", value);
  }

  private updateLanguage(lang: string) {
    this.language = lang;
  }

  private setLanguage(): void {
    this.setItemsForSelect();

    const language = navigator.language;
    const expreg = /esa*/;

    if (expreg.test(language)) {
      this.$i18n.locale = "es";
      this.language = "es";
    } else {
      this.$i18n.locale = "en";
      this.language = "en";
    }

    this.$store.dispatch("language/setLanguage", this.language);
  }

  private setItemsForSelect(): void {
    const shorten = this.small ? "-short" : "";
    this.languages = [
      {
        text: this.$tc("LocaleSwitcher.spanish" + shorten),
        value: "es",
      },
      {
        text: this.$tc("LocaleSwitcher.english" + shorten),
        value: "en",
      },
    ];
  }
}
