import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getWishlistByUser(payload: { page: number; size: number; user_id: string }) {
    return API_URL.get(
      `/wishlist/${payload.user_id}?page=${payload.page}&size=${payload.size}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getWishlistToVerifyAProduct(user_id: string) {
    return API_URL.get(`/wishlist/all/${user_id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  addProductToWishlist(product_id: string) {
    return API_URL.post(
      `/wishlist`,
      {
        product: {
          id: product_id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  removeProductFromWishlist(id: number) {
    return API_URL.delete(`/wishlist/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
