













































import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { FormValidations } from "@/mixins/form-validations";
import { CustomDate } from "@/mixins/custom-date";
import { Notification } from "@/models/notification.interface";
import { Feedback } from "@/models/feedback.interface";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class FeedbackDialog extends mixins(
  FormValidations,
  CustomDate,
  StyleCheck
) {
  @Prop() feedbackDialog = false;
  dialog = false;
  feedbackMessage = "";
  loading = false;

  $refs!: {
    feedbackForm: HTMLFormElement;
  };

  @Watch("feedbackDialog")
  private dialogHandler(): void {
    if (this.feedbackDialog) {
      this.dialog = true;
    } else {
      this.dialog = false;
    }
  }

  @Watch("dialog")
  private closedDialog() {
    if (!this.dialog) {
      this.$emit("closeDialog");
    }
  }

  private async sendFeedback() {
    this.loading = true;
    if (this.$refs.feedbackForm.validate()) {
      const feedback: Feedback = {
        text: this.feedbackMessage,
        date: this.currentDate(),
      };
      await this.$store
        .dispatch("feedback/sendFeedback", { feedback: feedback })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("Feedback.FDsuccess"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Feedback.FDerror"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private close() {
    this.feedbackMessage = "";
    this.resetFormValidations([this.$refs.feedbackForm]);
    this.$emit("closeDialog");
  }
}
