import Vue from "vue";
import WithdrawalService from "@/services/withdrawal.service";
import { WithdrawalState } from "./withdrawal.state";

export default {
  namespaced: true,
  state: {
    userWithdrawals: [],
    withdrawals: [],
    vouchers: [],
    voucherProgress: 0,
    exceptionalUsers: [],
  },
  getters: {
    getExceptionalUsers: (state: WithdrawalState) => state.exceptionalUsers,
    getUserWithdrawals: (state: WithdrawalState) => state.userWithdrawals,
    getWithdrawals: (state: WithdrawalState) => state.withdrawals,
    getVouchers: (state: WithdrawalState) => state.vouchers,
    getVoucherProgress: (state: WithdrawalState) => state.voucherProgress,
  },
  mutations: {
    setExceptionalUsers(state: WithdrawalState, exceptionalUsers: any[]) {
      Vue.set(state, "exceptionalUsers", exceptionalUsers);
    },
    setUserWithdrawals(state: WithdrawalState, userWithdrawals: any[]) {
      Vue.set(state, "userWithdrawals", userWithdrawals);
    },
    setWithdrawals(state: WithdrawalState, withdrawals: any[]) {
      Vue.set(state, "withdrawals", withdrawals);
    },
    setVouchers(state: WithdrawalState, vouchers: any[]) {
      Vue.set(state, "vouchers", vouchers);
    },
    setVoucherProgress(state: WithdrawalState, voucherProgress: number) {
      Vue.set(state, "voucherProgress", voucherProgress);
    },
  },
  actions: {
    getExceptionalUsers: async (context: any, search: string) => {
      try {
        const users = (
          await WithdrawalService.usersForExceptionalWithdrawalRequest(search)
        ).data.users;

        context.commit("setExceptionalUsers", users);
      } catch (error) {
        throw new Error(error);
      }
    },
    getUserWithdrawals: async (
      context: any,
      payload: { page: number; size: number; withdrawal_id: string }
    ) => {
      try {
        const withdrawals = (
          await WithdrawalService.getAllWithdrawalsByUser(payload)
        ).data;

        context.commit("setUserWithdrawals", withdrawals);
      } catch (error) {
        throw new Error(error);
      }
    },
    getWithdrawals: async (
      context: any,
      payload: { page: number; size: number; withdrawal_id: string }
    ) => {
      try {
        const withdrawals = (await WithdrawalService.getWithdrawals(payload))
          .data;

        context.commit("setWithdrawals", withdrawals);
      } catch (error) {
        throw new Error(error);
      }
    },
    getVouchers: async (
      context: any,
      payload: { page: number; size: number }
    ) => {
      try {
        const vouchers = (await WithdrawalService.getVouchers(payload)).data;

        context.commit("setVouchers", vouchers);
      } catch (error) {
        throw new Error(error);
      }
    },
    requestWithdrawal: async (context: any, amount: number) => {
      try {
        await WithdrawalService.requestWithdrawal(amount);
      } catch (error) {
        throw new Error(error);
      }
    },
    exceptionalWithdrawalRequest: async (context: any, payload: any) => {
      try {
        await WithdrawalService.exceptionalWithdrawalRequest(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    uploadVoucher: async (context: any, payload: FormData) => {
      try {
        return await WithdrawalService.uploadVoucher(
          payload,
          (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((100 * loaded) / total);
            context.commit("setVoucherProgress", progress);
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    resetVoucherProgress: async (context: any) => {
      context.commit("setVoucherProgress", 0);
    },
    addWithdrawalVoucher: async (
      context: any,
      payload: {
        withdrawal_id: string;
        amount: number;
        voucher_file: number;
        platform: number;
      }
    ) => {
      try {
        await WithdrawalService.addWithdrawalVoucher(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
