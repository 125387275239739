import { checkPerms } from "@/utils/checkPerms";
import _Vue from "vue";

const CheckPerms = {
  install(Vue: typeof _Vue) {
    Vue.prototype.$perms = {
      check: checkPerms,
    };
  },
};

declare module "vue/types/vue" {
  interface Vue {
    $perms: {
      check: typeof checkPerms;
    };
  }
}

export default CheckPerms;
