import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getPenaltiesByClient(payload) {
    return API_URL.get(`/users/single/${payload.client}/penalties`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
      params: {
        ...(payload.status && { status: payload.status }),
        page: payload.page,
        size: payload.size,
      },
    });
  },
  getAllPenalties(payload) {
    return API_URL.get(`/penalties`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
      params: {
        ...(payload.status && { status: payload.status }),
        page: payload.page,
        size: payload.size,
        ...(payload.text && { text: payload.text }),
      },
    });
  },
  getSinglePenalty(payload) {
    return API_URL.get(`/penalties/single/${payload}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getPenaltyTypes() {
    return API_URL.get(`/penalties/types`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createPenalty(payload) {
    return API_URL.post(`/users/single/${payload.client}/penalties`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updatePenalty(payload) {
    return API_URL.put(`/penalties/single/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
