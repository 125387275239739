import Vue from "vue";
import AuthenticationService from "@/services/authentication.service";
import { AuthenticationState } from "@/store/modules/Authentication/authentication.state";
import { User } from "@/models/user.interface";
import info from "@/utils/info";

const initialState = () => {
  return {
    user: null,
    role: null,
    context: null,
    privileges: [],
    userAvailable: true,
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getUser: (state: AuthenticationState) => state.user,
    getRole: (state: AuthenticationState) => state.role,
    getContext: (state: AuthenticationState) => state.context,
    getPrivileges: (state: AuthenticationState) => state.privileges,
    getUserAvailable: (state: AuthenticationState) => state.userAvailable,
  },
  mutations: {
    setUser(state: AuthenticationState, user: User) {
      Vue.set(state, "user", user);
    },
    setUserAvailable(state: AuthenticationState, userAvailable: boolean) {
      Vue.set(state, "userAvailable", userAvailable);
    },
    setRole(state: AuthenticationState, role: string) {
      Vue.set(state, "role", role);
    },
    setContext(state: AuthenticationState, context: string) {
      Vue.set(state, "context", context);
    },
    setPrivileges(
      state: AuthenticationState,
      privileges: { name: string; type: string }[]
    ) {
      Vue.set(state, "privileges", privileges);
    },
    reset(state: AuthenticationState) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        Vue.set(state, key, newState[key]);
      });
    },
  },
  actions: {
    signIn: async (context: any, payload: any) => {
      const response = (await AuthenticationService.signIn(payload)).data;
      info.setData({ token: response.token });
      context.commit("setUser", {
        id: response.user.id,
        alias: response.user.alias,
        email: response.user.email,
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        contexts: response.user.contexts,
        block_product_comments: response.user.block_product_comments,
        block_bill_comments: response.user.block_bill_comments,
        penalty_grade: response.user.penalty_grade,
        status: response.user.status,
      });
      context.commit("setRole", response.user.role);
      context.commit("setContext", response.user.contexts[0].name);
      context.commit("setPrivileges", response.user.privileges);
    },
    updateUserInfo: async (context: any, payload: any) => {
      const response = (await AuthenticationService.getUserProfile(payload))
        .data;
      context.commit("setUser", {
        id: response.id,
        alias: response.alias,
        email: response.email,
        first_name: response.first_name,
        last_name: response.last_name,
        contexts: response.role.context,
        block_product_comments: response.block_product_comments,
        block_bill_comments: response.block_bill_comments,
        penalty_grade: response.penalty_grade,
        status: response.status,
      });
      context.commit("setRole", response.role.name);
      context.commit("setPrivileges", response.role.privilege);
    },
    updateUserInfoByInitialData: async (context: any, data: any) => {
      context.commit("setUser", {
        id: data.id,
        alias: data.alias,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        contexts: data.role.context,
        block_product_comments: data.block_product_comments,
        block_bill_comments: data.block_bill_comments,
        penalty_grade: data.penalty_grade,
        status: data.status,
      });
      context.commit("setRole", data.role.name);
      context.commit("setPrivileges", data.role.privilege);
    },
    getUser: async (_context: any, payload: { id: string }) => {
      try {
        return (await AuthenticationService.getUserProfile(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    setUser: async (_context: any, payload: { id: string; user: User }) => {
      try {
        await AuthenticationService.setUserProfile(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    recoverPassword: async (_context: any, payload: any) => {
      try {
        await AuthenticationService.recoverPassword(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    changePassword: async (_context: any, payload: any) => {
      try {
        await AuthenticationService.changePassword(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    changeEmail: async (_context: any, payload: any) => {
      try {
        await AuthenticationService.changeEmail(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    clientSignUp: async (_context: any, payload: any) => {
      try {
        return await AuthenticationService.clientSignUp(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    clientValidate: async (_context: any, payload: any) => {
      try {
        await AuthenticationService.clientValidate(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    staffValidate: async (_context: any, payload: any) => {
      try {
        await AuthenticationService.staffValidate(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    userExists: async (_context: any, payload: any) => {
      try {
        const response = (await AuthenticationService.userExists(payload)).data;
        return response.exists;
      } catch (error) {
        throw new Error(error);
      }
    },
    setContext: async (context: any, payload: string) => {
      context.commit("setContext", payload);
    },
    logOut: async (context: any) => {
      const session = (await AuthenticationService.logOut()).data.session;
      document.cookie =
        session + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      context.commit("reset");
      info.secureLS.removeAll();
    },
    validateHCaptcha: async (context: any, payload: any) => {
      try {
        await AuthenticationService.validateHCaptcha(payload);
        return true;
      } catch (error) {
        return false;
      }
    },
    checkIfUserIsAvailable: async (context: any, id: string) => {
      try {
        const response = (
          await AuthenticationService.checkIfUserIsAvailable(id)
        ).data;
        context.commit("setUserAvailable", response.available);
      } catch (error) {
        throw new Error(error);
      }
    },
    checkIfUserIsAvailableByInitiaData: async (
      context: any,
      isAvailable: boolean
    ) => {
      try {
        context.commit("setUserAvailable", isAvailable);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
