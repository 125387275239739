import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  createTraditionalPayment(payload: any) {
    return API_URL.post(`/payments/traditional/client`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  addFilesForTraditionalPayment(payload: FormData, progress) {
    return API_URL.post(`/files/payment/traditional`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  createCryptoPayment(payload: any) {
    return API_URL.post(`/payments/crypto/client`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  addFilesForCryptoPayment(payload: FormData, progress) {
    return API_URL.post(`/files/payment/crypto`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  updateStatusForCryptoPayment(payload: { id: string; status: string }) {
    return API_URL.put(`/payments/crypto/status/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateStatusForTraditionalPayment(payload: { id: string; status: string }) {
    return API_URL.put(`/payments/traditional/status/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createBalancePayment(payload: any) {
    return API_URL.post(`/payments/balance/client`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getCryptoPayments(payload: {
    page: string;
    size: number;
    payment_id: string;
  }) {
    return API_URL.get(
      `/payments/crypto?size=${payload.size}&page=${payload.page}&payment_id=${payload.payment_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getTraditionalPayments(payload: {
    page: string;
    size: number;
    payment_id: string;
  }) {
    return API_URL.get(
      `/payments/traditional?size=${payload.size}&page=${payload.page}&payment_id=${payload.payment_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getBalancePayments(payload: {
    page: string;
    size: number;
    payment_id: string;
  }) {
    return API_URL.get(
      `/payments/balance?size=${payload.size}&page=${payload.page}&payment_id=${payload.payment_id}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
