import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getFunctionalities() {
    return API_URL.get(`/functionalities`);
  },
  updateFunctionalityStatus(payload, id) {
    return API_URL.put(`/functionalities/status/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
