import Vue from "vue";
import SocialNetworksService from "@/services/social-networks.service";
import { SocialNetworksState } from "@/store/modules/SocialNetworks/socialNetworks.state";
import { SocialNetwork } from "@/models/social-network.interface";

export default {
  namespaced: true,
  state: {
    socialNetworks: [],
  },
  getters: {
    getSocialNetworks: (state: SocialNetworksState) => state.socialNetworks,
  },
  mutations: {
    setSocialNetworks(
      state: SocialNetworksState,
      socialNetworks: SocialNetwork[]
    ) {
      Vue.set(state, "socialNetworks", socialNetworks);
    },
  },
  actions: {
    getSocialNetworksToClient: async (context: any) => {
      try {
        const socialNetworks = (await SocialNetworksService.getSocialNetworks())
          .data;
        context.commit("setSocialNetworks", socialNetworks);
      } catch (error) {
        throw new Error(error);
      }
    },
    getSocialNetworksToStaff: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const socialNetworks = (
          await SocialNetworksService.getSocialNetworksToAdmin(payload)
        ).data;
        context.commit("setSocialNetworks", socialNetworks);
      } catch (error) {
        throw new Error(error);
      }
    },
    createSocialNetwork: async (context: any, payload: SocialNetwork) => {
      try {
        await SocialNetworksService.createSocialNetwork(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateSocialNetwork: async (context: any, payload: SocialNetwork) => {
      try {
        await SocialNetworksService.updateSocialNetwork(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateSocialNetworkStatus: async (
      context: any,
      payload: { id: number; status: string }
    ) => {
      try {
        await SocialNetworksService.updateSocialNetworkStatus(
          payload.id,
          payload.status
        );
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
