import Vue from "vue";
import BillsService from "@/services/bills.service";
import { BillState } from "./bill.state";

export default {
  namespaced: true,
  state: {
    userBills: [],
    bills: {},
    bill: {},
    billForChat: {},
  },
  getters: {
    getUserBills: (state: BillState) => state.userBills,
    getBills: (state: BillState) => state.bills,
    getBill: (state: BillState) => state.bill,
    getBillForChat: (state: BillState) => state.billForChat,
  },
  mutations: {
    setUserBills(state: any, userBills: any) {
      Vue.set(state, "userBills", userBills);
    },
    setBills(state: any, bills: any) {
      Vue.set(state, "bills", bills);
    },
    setBill(state: any, bill: any) {
      Vue.set(state, "bill", bill);
    },
    setBillForChat(state: any, bill: any) {
      Vue.set(state, "billForChat", bill);
    },
    combineUserBills(state: any, bills: any) {
      const listOfBills = state.userBills.bills;
      bills.bills.forEach((bill) => {
        listOfBills.push(bill);
      });
      bills.bills = listOfBills;
      Vue.set(state, "bills", bills);
    },
  },
  actions: {
    getUserBills: async (
      context: any,
      payload: {
        page: string;
        size: number;
        user_id: string;
        operation: string;
        bill_id: string;
      }
    ) => {
      try {
        const bills = (await BillsService.getUserBills(payload)).data;
        context.commit("setUserBills", bills);
      } catch (error) {
        throw new Error(error);
      }
    },
    getBills: async (
      context: any,
      payload: {
        page: string;
        size: number;
        operation: string;
        bill_id: string;
      }
    ) => {
      try {
        const bills = (await BillsService.getBills(payload)).data;
        context.commit("setBills", bills);
      } catch (error) {
        throw new Error(error);
      }
    },
    getBill: async (context: any, id: string) => {
      try {
        const bill = (await BillsService.getSingleBill(id)).data;
        context.commit("setBill", bill);
      } catch (error) {
        throw new Error(error);
      }
    },
    getBillForChat: async (context: any, id: string) => {
      try {
        const bill = (await BillsService.getBillForChat(id)).data;
        context.commit("setBillForChat", bill);
      } catch (error) {
        throw new Error(error);
      }
    },
    resetBillForChat: (context: any) => {
      context.commit("setBillForChat", {});
    },
    getUserBillsForDetail: async (
      context: any,
      payload: {
        page: string;
        size: number;
        operation: string;
        user_id: string;
        combine: boolean;
        bill_id: string;
      }
    ) => {
      try {
        const bills = (await BillsService.getUserBills(payload)).data;
        if (payload.combine) {
          context.commit("combineUserBills", bills);
        } else {
          context.commit("setUserBills", bills);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    updateBillStatus: async (
      context: any,
      payload: {
        id: string;
        status: string;
      }
    ) => {
      try {
        await BillsService.updateBillStatus(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
