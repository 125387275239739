






































































































































































































































































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";
import { UserValidations } from "@/mixins/user-validations";
import { StyleCheck } from "@/mixins/style-check";
import { FileCheck } from "@/mixins/file-check";
import { Socket } from "vue-socket.io-extended";

@Component({})
export default class BillChat extends mixins(
  FormValidations,
  CustomDate,
  UserValidations,
  StyleCheck,
  FileCheck
) {
  @Prop() bill_id!: string;
  @Prop({ default: false }) is_icon!: boolean;
  @Prop({ default: false, required: false }) disabled!: boolean;
  @Prop({ default: false, required: false }) hidden!: boolean;
  @Prop({ default: false, required: false }) showHidden!: boolean;
  dialog = false;
  text = "";
  readonly = false;
  input: any = null;
  bill_comments: any = {};
  file: any = null;
  sending = false;
  $refs!: {
    inputChat: HTMLFormElement;
    inputRef: HTMLInputElement;
  };

  @Watch("showHidden")
  private async openHiddenChat(value) {
    if (this.hidden) {
      if (value) {
        this.dialog = true;
        await this.getBillInfo();
      } else {
        this.$store.dispatch("bill/resetBillForChat");
      }
    }
  }

  private scrollChat() {
    let chatbox;
    chatbox = document.getElementsByClassName("v-dialog--fullscreen");
    if (chatbox[chatbox.length - 1] !== undefined) {
      chatbox = chatbox[chatbox.length - 1];
      chatbox.scrollTop = chatbox.scrollHeight;
    }
  }

  private updated() {
    this.$nextTick(function () {
      if (this.text == "" && this.file == null) {
        this.scrollChat();
      }

      if (this.dialog) {
        this.input = document.getElementById("billChat");
        setTimeout(() => {
          this.input.focus();
        }, 100);
      }
    });
  }

  private async created() {
    if (!this.hidden) await this.getBillComments();
  }

  @Socket("chat:opened")
  private async chatWasOpened(payload) {
    if (this.bill_id !== "" && payload.bill_id == this.bill_id) {
      await this.getBillComments();
    }
  }

  @Watch("bill_id")
  @Socket("chat:backoffice-updated")
  private async chatsWereUpdated() {
    if (this.bill_id !== "") {
      const currentText = this.text;
      const currentFile = this.file;

      if (this.sending) {
        this.file = null;
        this.text = "";
      } else {
        this.file = currentFile;
        this.text = currentText;
      }

      await this.getBillComments();
      await this.openGlobalHanlder();
      this.scrollChat();
    }
  }

  @Watch("dialog")
  private async openGlobalHanlder(value = null) {
    if (value) {
      await this.getBillInfo();
      this.$store.dispatch("notifications/setChatOpened", true);
    }

    const comments_opened: any = [];
    if (this.dialog) {
      if (!(this.file !== null || this.text !== "")) {
        this.file = null;
        this.text = "";
      }
      this.bill_comments.comments.forEach((comment) => {
        if (
          this.context == this.$constants.CONTEXT.FRONTOFFICE &&
          comment.user.role.name !== this.$constants.USER_TYPE.CLIENT &&
          comment.status.name !== this.$constants.STATUS.OPENED
        ) {
          comments_opened.push(comment.id);
        }

        if (
          this.context == this.$constants.CONTEXT.BACKOFFICE &&
          comment.user.role.name == this.$constants.USER_TYPE.CLIENT &&
          comment.status.name !== this.$constants.STATUS.OPENED
        ) {
          comments_opened.push(comment.id);
        }
      });

      if (comments_opened.length > 0) {
        this.$store
          .dispatch("comments/updateBillComments", {
            bill_id: this.bill_id,
            comments: comments_opened,
            status: this.$constants.STATUS.OPENED,
          })
          .then(async () => {
            await this.getBillComments();
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Views.bc-e1"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
      }
    } else {
      if (value == false) this.$store.dispatch("bill/resetBillForChat");
    }
  }

  private async getBillComments() {
    if (this.bill_id !== undefined && this.bill_id !== "") {
      await this.$store
        .dispatch("comments/getBillComments", this.bill_id)
        .then((response) => {
          this.bill_comments = response;
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.bc-e2"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
    }
  }

  private async getBillInfo() {
    await this.$store
      .dispatch("bill/getBillForChat", this.bill_id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.bc-ei1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async sendComment() {
    if (
      this.$refs.inputChat.validate() &&
      this.text !== undefined &&
      this.text !== null &&
      this.text !== ""
    ) {
      this.sending = true;
      const comment_text = this.text;
      this.readonly = true;
      await this.$store
        .dispatch("comments/sendBillComment", {
          text: comment_text,
          bill_id: this.bill_id,
          onlyText: this.file !== null ? false : true,
        })
        .then(async (response) => {
          if (this.file !== null) {
            this.$store.dispatch("comments/resetProgress");
            const payload = new FormData();
            payload.append(
              "DATA",
              JSON.stringify({
                bill_id: this.bill_id,
                bill_comment_id: response.id,
              })
            );

            payload.append(
              this.$constants.FILE_CATEGORY.BILL_COMMENT,
              this.file as File
            );
            await this.$store
              .dispatch("comments/uploadBillCommentFile", payload)
              .catch(() => {
                const Error: Notification = {
                  message: this.$tc("Views.bc-e3"),
                  timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
                  top: true,
                  bottom: false,
                  left: false,
                  right: false,
                  currentPath: this.$route.fullPath,
                  error: true,
                };

                this.$store.dispatch("notifications/showNotification", Error);
              });
          }

          this.text = "";
          this.file = null;
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.bc-e4"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.$refs.inputChat.reset();
          this.$store.dispatch("comments/resetProgress");
          this.readonly = false;
          this.sending = false;
        });
    }
  }

  private closeChat() {
    this.dialog = false;
    this.$store.dispatch("notifications/setChatOpened", false);
    this.$emit("resetChatState");
  }

  private get commentProgress() {
    return this.$store.getters["comments/getCommentProgress"];
  }

  private get billInfo() {
    return this.$store.getters["bill/getBillForChat"];
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }

  private clearFile() {
    this.file = null;
  }

  private checkPreviewType(previewType: string): boolean {
    let type = previewType.toLowerCase();

    if (
      type == ".jpeg" ||
      type == ".png" ||
      type == ".jpg" ||
      type == ".svg" ||
      type == ".webm"
    ) {
      return true;
    } else {
      return false;
    }
  }
}
