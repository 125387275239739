import Vue from "vue";
import { StylesState } from "@/store/modules/Styles/styles.state";
import StylesService from "@/services/styles.service";

export default {
  namespaced: true,
  state: {
    colors: [],
    darkMode: undefined,
  },
  getters: {
    getColors: (state: StylesState) => state.colors,
    darkMode: (state: StylesState) => state.darkMode,
  },
  mutations: {
    setColors(state: StylesState, colors: any[]) {
      Vue.set(state, "colors", colors);
    },
    setTheme(state: StylesState, theme: boolean) {
      Vue.set(state, "darkMode", theme);
    },
  },
  actions: {
    setStyles: async (context: any, payload: any) => {
      try {
        await StylesService.setStyles(payload);
        context.commit("setColors", payload.styles);
      } catch (error) {
        throw new Error(error);
      }
    },
    setTheme: async (context: any, payload: any) => {
      try {
        context.commit("setTheme", payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    getStyles: async (context: any) => {
      try {
        const response = (await StylesService.getStyles()).data;
        const colors = response.filter((value: any) => {
          return value.type == "COLOR";
        });
        context.commit("setColors", colors);
      } catch (error) {
        throw new Error(error);
      }
    },
    setStylesByInitialData: async (context: any, styles: any) => {
      try {
        const colors = styles.filter((value: any) => {
          return value.type == "COLOR";
        });
        context.commit("setColors", colors);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
