import { Detail } from "@/models/detail.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getDetails(payload: { page: number; size: number; text: string }) {
    return API_URL.get(
      `/details?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getAssignableDetails() {
    return API_URL.get(`/details/assignable`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createDetail(payload: { detail: Detail }) {
    return API_URL.post(`/details`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateDetail(payload: { detail: Detail }) {
    return API_URL.put(`/details/update/${payload.detail.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateDetailStatus(id: number, payload: string) {
    return API_URL.put(
      `/details/status/${id}`,
      { status: payload },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
