import { Feedback } from "@/models/feedback.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  sendFeedback(payload: Feedback) {
    return API_URL.post(`/feedbacks`, payload);
  },
  getFeedbacks(payload: { page: number; size: number; text: string }) {
    return API_URL.get(
      `/feedbacks?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  deleteFeedback(payload: { id: number; status: string }) {
    return API_URL.put(
      `/feedbacks/status/${payload.id}`,
      {
        status: payload.status,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
