import { Term } from "@/models/term.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getTerms(payload: { page: number; size: number; type: string }) {
    return API_URL.get(
      `/terms?page=${payload.page}&size=${payload.size}&type=${payload.type}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getAssignableTerms() {
    return API_URL.get(`/terms/assignable`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createTerm(payload: { term: Term }) {
    return API_URL.post(`/terms`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateTerm(payload: { term: Term }) {
    return API_URL.put(`/terms/update/${payload.term.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateTermStatus(id: number, payload: string) {
    return API_URL.put(
      `/terms/status/${id}`,
      { status: payload },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
