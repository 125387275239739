import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getSecuritySettings() {
    return API_URL.get(`/applications/security-settings`);
  },
  getApplications(payload: {
    page: number;
    size: number;
    text: string;
    status: string;
  }) {
    const query =
      (payload.text !== "" ? `&text=${payload.text}` : "") +
      (payload.status !== "" && payload.status !== "N/A"
        ? `&status=${payload.status}`
        : "");

    return API_URL.get(
      `/applications?page=${payload.page}&size=${payload.size}${query}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getApplicationByID(id: string) {
    return API_URL.get(`/applications/info/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createApplication(payload) {
    return API_URL.post(
      `/applications?userLogged=${payload.userLogged}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateApplication(payload) {
    return API_URL.patch(`/applications/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
