import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import modules from "./modules/index";
import utils from "@/utils/info";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      key: "bt-" + process.env.VUE_APP_LS_KEY,
      paths: [
        "authentication",
        "functionalities",
        "navigation",
        "language",
        "settings.messageOfTheDayState",
        "settings.messageOfTheDay",
        "settings.sessionName",
        "application.phone",
        "application.email",
        "application.name",
        "application.amount",
        "application.description",
        "application.lastUpdate",
        "application.dialCode",
        "application.iso2",
        "application.formatedPhone",
      ],
      storage: {
        getItem: (key) => utils.secureLS.get(key),
        setItem: (key, value) => utils.secureLS.set(key, value),
        removeItem: (key) => utils.secureLS.remove(key),
      },
    }),
  ],
});
