import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getUserKYC(user_id: string) {
    return API_URL.get(`/kyc/${user_id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createUserKYC(payload: { description: any; user_id: string }) {
    return API_URL.post(
      `/kyc/${payload.user_id}`,
      {
        kyc: {
          description: payload.description,
        },
      },
      {
        withCredentials: false,
      }
    );
  },
  addKYCFiles(payload: { files: FormData; kyc_id: any; user_id: any }) {
    return API_URL.post(
      `/files/kyc/${payload.user_id}/${payload.kyc_id}`,
      payload.files,
      {
        withCredentials: false,
      }
    );
  },
};
