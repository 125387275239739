import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
import info from "@/utils/info";
import { VueEasyJwt } from "vue-easy-jwt";
import { Privilege } from "@/models/privilege.interface";
const jwt = new VueEasyJwt();

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/first-steps",
    name: "FirstSteps",
    component: () => import("@/views/FirstSteps.vue"),
    beforeEnter(to, from, next) {
      const firstStepsRegistered =
        store.getters["settings/getFirstStepsRegistered"];
      if (!firstStepsRegistered) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      hideSystemBar: true,
      hideHeader: true,
      hideFooter: true,
      hideSideBar: true,
      hideAdministratorSideBar: true,
      hideBottomNavigation: true,
    },
  },
  {
    path: "/newsletter/unsubscribe/:token",
    name: "Unsubscribe",
    component: () => import("@/views/Authentication/Unsubscribe.vue"),
    meta: {
      hideSystemBar: true,
      hideHeader: true,
      hideFooter: true,
      hideSideBar: true,
      hideAdministratorSideBar: true,
      hideBottomNavigation: true,
    },
  },
  {
    path: "/project-application",
    name: "ProjectApplication",
    component: () => import("@/views/External/ProjectApplication.vue"),
    meta: {
      hideSystemBar: true,
      hideHeader: true,
      hideFooter: true,
      hideSideBar: true,
      hideAdministratorSideBar: true,
      hideBottomNavigation: true,
    },
  },
  {
    path: "/user/validate-email/:token",
    name: "ValidateClient",
    component: () => import("@/views/Authentication/ValidateClient.vue"),
    meta: {
      hideSystemBar: true,
      hideHeader: true,
      hideFooter: true,
      hideSideBar: true,
      hideAdministratorSideBar: true,
      hideBottomNavigation: true,
    },
  },
  {
    path: "/user/change-password/:token",
    name: "ChangePassword",
    component: () => import("@/views/Authentication/ChangePassword.vue"),
    meta: {
      hideSystemBar: true,
      hideHeader: true,
      hideFooter: true,
      hideSideBar: true,
      previousRoute: "/profile",
      hideAdministratorSideBar: true,
      hideAdministratorHeader: true,
      hideBottomNavigation: true,
    },
  },
  {
    path: "/user/change-email/:token",
    name: "ChangeEmail",
    component: () => import("@/views/Authentication/ChangeEmail.vue"),
    meta: {
      hideSystemBar: true,
      hideHeader: true,
      hideFooter: true,
      hideSideBar: true,
      previousRoute: "/profile",
      hideAdministratorSideBar: true,
      hideAdministratorHeader: true,
      hideBottomNavigation: true,
    },
  },
  {
    path: "/user/set-new-password/:token",
    name: "ValidateStaff",
    component: () => import("@/views/Authentication/ValidateStaff.vue"),
    meta: {
      hideSystemBar: true,
      hideHeader: true,
      hideFooter: true,
      hideSideBar: true,
      hideAdministratorSideBar: true,
      hideBottomNavigation: true,
    },
  },
  {
    path: "/",
    component: () => import("@/views/Client/ClientApp.vue"),
    beforeEnter: (to, from, next) => {
      const context = store.getters["authentication/getContext"];
      if (context == Vue.prototype.$constants.CONTEXT.BACKOFFICE) {
        next("/staff/home");
      } else {
        next();
      }
    },
    children: [
      {
        path: "profile",
        name: "Client Profile",
        component: () => import("@/views/Authentication/Profile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/user/penalties/:page",
        name: "Client Penalties",
        component: () => import("@/views/Client/ClientPenalties.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          hideFooter: true,
          hideSideBar: true,
          previousRoute: "/profile",
          hideAdministratorSideBar: true,
          hideAdministratorHeader: true,
          hideBottomNavigation: true,
          requiresAuth: true,
        },
      },
      {
        path: "home",
        name: "ClientHome",
        component: () => import("@/views/Client/Home.vue"),
      },
      {
        path: "recover-password",
        name: "RecoverPassword",
        component: () => import("@/views/Authentication/RecoverPassword.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          simpleHeader: true,
          previousRoute: "sign-in",
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
        },
      },
      {
        path: "email-sent",
        name: "EmailSent",
        component: () => import("@/views/Authentication/EmailSent.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
        },
      },
      {
        path: "kyc-validation-process",
        name: "KYCValidationProcess",
        component: () =>
          import("@/views/Authentication/KYCValidationProcess.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
        },
      },
      {
        path: "sign-in",
        name: "SignIn",
        component: () => import("@/views/Authentication/SignIn.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          simpleHeader: true,
          previousRoute: "home",
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
        },
      },
      {
        path: "sign-up",
        name: "SignUp",
        component: () => import("@/views/Authentication/SignUp.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          simpleHeader: true,
          previousRoute: "sign-in",
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
        },
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicy",
        component: () => import("@/views/Client/PrivacyPolicy.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "sales-and-payment-policy",
        name: "SalesAndPaymentPolicy",
        component: () => import("@/views/Client/SalesAndPaymentPolicy.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "terms-and-conditions",
        name: "TermsAndConditions",
        component: () => import("@/views/Client/TermsAndConditions.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "general-information",
        name: "GeneralInformation",
        component: () => import("@/views/Client/GeneralInformation.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "frequently-asked-questions",
        name: "FAQs",
        component: () => import("@/views/Client/FAQ.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "catalogue/search/:text/:page",
        name: "CatalogueBySearchBar",
        component: () => import("@/views/Client/CatalogueBySearchBar.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "catalogue/category/:category_id/:page",
        name: "CatalogueByCategory",
        component: () => import("@/views/Client/CatalogueByCategory.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "catalogue/product/investment/:product_id/detail",
        name: "ProductDetailForClient",
        component: () => import("@/views/Client/ProductDetailForClient.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "catalogue/explore/:page",
        name: "CatalogueByExplore",
        component: () => import("@/views/Client/CatalogueByExplore.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "categories",
        name: "CategoriesForClient",
        component: () => import("@/views/Client/CategoriesForClient.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
        },
      },
      {
        path: "user/wishlist/:page",
        name: "WishlistProducts",
        component: () => import("@/views/Client/WishlistProducts.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "investment/checkout",
        name: "Checkout",
        component: () => import("@/views/Client/InvestmentCheckout.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          simpleHeader: false,
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          checkout: true,
          requiresAuth: true,
        },
      },
      {
        path: "investment/checkout/registered-payment/success/:id",
        name: "RegisteredPayment",
        component: () => import("@/views/Client/RegisteredPayment.vue"),
        meta: {
          hideSystemBar: true,
          simpleHeader: true,
          hideHeader: true,
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
          requiresAuth: true,
          previousRoute: "/user/shopping/1",
        },
      },
      {
        path: "user/shopping/:page",
        name: "UserBills",
        component: () => import("@/views/Client/UserBills.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "user/investments/:page",
        name: "UserInvestments",
        component: () => import("@/views/Client/UserInvestments.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "user/withdrawals/:page",
        name: "UserWithdrawals",
        component: () => import("@/views/Client/UserWithdrawals.vue"),
        meta: {
          hideSystemBar: false,
          hideHeader: false,
          simpleHeader: false,
          hideFooter: false,
          hideSideBar: false,
          hideAdministratorSideBar: true,
          requiresAuth: true,
        },
      },
      {
        path: "user/withdrawal/accounts/:page",
        name: "UserWithdrawalAccounts",
        component: () => import("@/views/Client/UserWithdrawalAccounts.vue"),
        meta: {
          hideSystemBar: true,
          hideHeader: true,
          simpleHeader: true,
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          requiresAuth: true,
          previousRoute: "/user/withdrawals/1",
        },
      },
    ],
  },
  {
    path: "/staff",
    component: () => import("@/views/Staff/StaffApp.vue"),
    beforeEnter: (to, from, next) => {
      const context = store.getters["authentication/getContext"];
      if (
        context == null ||
        context == Vue.prototype.$constants.CONTEXT.FRONTOFFICE
      ) {
        next("/home");
      } else {
        next();
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "profile",
        name: "Staff Profile",
        component: () => import("@/views/Authentication/Profile.vue"),
      },
      {
        path: "home",
        name: "StaffHome",
        component: () => import("@/views/Staff/Home.vue"),
      },
      {
        path: "change-password/:token",
        name: "StaffChangePassword",
        component: () => import("@/views/Authentication/ChangePassword.vue"),
        meta: {
          hideAdministratorHeader: true,
          hideSystemBar: true,
          simpleHeader: true,
          previousRoute: "/staff/profile",
          hideHeader: true,
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
        },
      },
      {
        path: "change-email/:token",
        name: "StaffChangeEmail",
        component: () => import("@/views/Authentication/ChangeEmail.vue"),
        meta: {
          hideAdministratorHeader: true,
          hideSystemBar: true,
          simpleHeader: true,
          previousRoute: "/staff/profile",
          hideHeader: true,
          hideFooter: true,
          hideSideBar: true,
          hideAdministratorSideBar: true,
          hideBottomNavigation: true,
        },
      },
      {
        path: "categories",
        name: "Categories",
        component: () => import("@/views/Staff/Categories.vue"),
        meta: {
          privilege: "CATEGORY",
        },
      },
      {
        path: "roles",
        name: "Roles",
        component: () => import("@/views/Staff/Roles.vue"),
        meta: {
          privilege: "ROLE",
        },
      },
      {
        path: "roles/:id/detail",
        name: "RoleDetail",
        component: () => import("@/views/Staff/RoleDetail.vue"),
        meta: {
          hideAdministratorHeader: true,
          privilege: "ROLE",
        },
      },
      {
        path: "penalties",
        name: "Penalties",
        component: () => import("@/views/Staff/Penalties.vue"),
        meta: {
          privilege: "PENALTY",
        },
      },
      {
        path: "penalties/:id/detail",
        name: "PenaltyDetail",
        component: () => import("@/views/Staff/PenaltyDetail.vue"),
        meta: {
          hideAdministratorHeader: true,
          privilege: "PENALTY",
        },
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/views/Staff/Users.vue"),
        meta: {
          privilege: "USER",
        },
      },
      {
        path: "users/:id/detail",
        name: "UserDetail",
        component: () => import("@/views/Staff/UserDetail.vue"),
        meta: {
          hideAdministratorHeader: true,
          privilege: "USER",
        },
      },
      {
        path: "terms",
        name: "Terms",
        component: () => import("@/views/Staff/Terms.vue"),
        meta: {
          privilege: "TERM",
        },
      },
      {
        path: "details",
        name: "Details",
        component: () => import("@/views/Staff/Details.vue"),
        meta: {
          privilege: "DETAIL",
        },
      },
      {
        path: "social-networks",
        name: "SocialNetworks",
        component: () => import("@/views/Staff/SocialNetworks.vue"),
        meta: {
          privilege: "SOCIAL NETWORK",
        },
      },
      {
        path: "faqs",
        name: "FAQ",
        component: () => import("@/views/Staff/FAQ.vue"),
        meta: {
          privilege: "FAQ",
        },
      },
      {
        path: "styles",
        name: "SystemStyles",
        component: () => import("@/views/Staff/SystemStyles.vue"),
        meta: {
          privilege: "STYLE",
        },
      },
      {
        path: "information",
        name: "Information",
        component: () => import("@/views/Staff/Information.vue"),
        meta: {
          privilege: "CONFIGURATION",
        },
      },
      {
        path: "functionalities",
        name: "Functionalities",
        component: () => import("@/views/Staff/Functionalities.vue"),
        meta: {
          privilege: "FUNCTIONALITY",
        },
      },
      {
        path: "company",
        name: "Company",
        component: () => import("@/views/Staff/Company.vue"),
        meta: {
          privilege: "COMPANY",
        },
      },
      {
        path: "feedbacks",
        name: "Feedbacks",
        component: () => import("@/views/Staff/Feedbacks.vue"),
        meta: {
          privilege: "FEEDBACK",
        },
      },
      {
        path: "newsletter",
        name: "Newsletters",
        component: () => import("@/views/Staff/Newsletters.vue"),
        meta: {
          privilege: "NEWSLETTER",
        },
      },
      {
        path: "products",
        name: "Products",
        component: () => import("@/views/Staff/Products.vue"),
        meta: {
          privilege: "PRODUCT",
        },
      },
      {
        path: "applications",
        name: "Applications",
        component: () => import("@/views/Staff/Applications.vue"),
        meta: {
          privilege: "APPLICATION",
        },
      },
      {
        path: "applications/detail/:id",
        name: "CheckApplication",
        component: () => import("@/views/Staff/CheckApplication.vue"),
        meta: {
          privilege: "APPLICATION",
        },
      },
      {
        path: "products/create/:type",
        name: "ProductCreate",
        component: () => import("@/views/Staff/ProductForm.vue"),
        meta: {
          hideAdministratorHeader: true,
          privilege: "PRODUCT",
        },
      },
      {
        path: "products/update/:id",
        name: "ProductUpdate",
        component: () => import("@/views/Staff/ProductForm.vue"),
        meta: {
          hideAdministratorHeader: true,
          privilege: "PRODUCT",
        },
      },
      {
        path: "products/detail/:id",
        name: "ProductDetail",
        component: () => import("@/views/Staff/ProductDetail.vue"),
        meta: {
          hideAdministratorHeader: true,
          privilege: "PRODUCT",
        },
      },
      {
        path: "interaction/products/:page",
        name: "CommentsOnProducts",
        component: () => import("@/views/Staff/CommentsOnProducts.vue"),
        meta: {
          privilege: "PRODUCT COMMENT",
        },
      },
      {
        path: "interaction/bills/:page",
        name: "CommentsOnBills",
        component: () => import("@/views/Staff/CommentsOnBills.vue"),
        meta: {
          privilege: "BILL COMMENT",
        },
      },
      {
        path: "payment-platforms",
        name: "PaymentPlatforms",
        component: () => import("@/views/Staff/PaymentPlatforms.vue"),
        meta: {
          privilege: "PLATFORM",
        },
      },
      {
        path: "investments",
        name: "Sales",
        component: () => import("@/views/Staff/Sales.vue"),
        meta: {
          privilege: "BILL",
        },
      },
      {
        path: "withdrawals",
        name: "Withdrawals",
        component: () => import("@/views/Staff/Withdrawals.vue"),
        meta: {
          privilege: "WITHDRAWAL",
        },
      },
      {
        path: "vouchers",
        name: "Vouchers",
        component: () => import("@/views/Staff/Vouchers.vue"),
        meta: {
          privilege: "WITHDRAWAL",
        },
      },
      {
        path: "returns",
        name: "Returns",
        component: () => import("@/views/Staff/Returns.vue"),
        meta: {
          privilege: "BILL",
        },
      },
      {
        path: "payments",
        name: "Payments",
        component: () => import("@/views/Staff/Payments.vue"),
        meta: {
          privilege: "PAYMENT",
        },
      },
      {
        path: "investments/detail/:id",
        name: "BillDetail",
        component: () => import("@/views/Staff/BillDetail.vue"),
        meta: {
          privilege: "BILL",
        },
      },
      {
        path: "investments/payment-validation/:id",
        name: "PaymentValidation",
        component: () => import("@/views/Staff/PaymentValidation.vue"),
        meta: {
          privilege: "PAYMENT",
        },
      },
      {
        path: "returns/detail/:id",
        name: "ReturnDetail",
        component: () => import("@/views/Staff/ReturnDetail.vue"),
        meta: {
          privilege: "BILL",
        },
      },
      {
        path: "investments/check-bill-user/:id",
        name: "CheckUser",
        component: () => import("@/views/Staff/CheckUser.vue"),
        meta: {
          privilege: "BILL",
        },
      },
      {
        path: "users/:id/kyc-validation",
        name: "KYCValidation",
        component: () => import("@/views/Staff/KYCValidation.vue"),
        meta: {
          privilege: "USER",
        },
      },
      {
        path: "support",
        name: "StaffSupport",
        component: () => import("@/views/Support/StaffSupport.vue"),
      },
      {
        path: "support/resource/:topic/:resource",
        name: "StaffResource",
        component: () => import("@/views/Support/StaffResource.vue"),
        meta: {
          simpleHeader: true,
          previousRoute: "/staff/support",
          hideAdministratorSideBar: false,
        },
      },
    ],
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      hideSystemBar: true,
      simpleHeader: true,
      hideHeader: true,
      hideFooter: false,
      hideSideBar: true,
      hideAdministratorSideBar: true,
      hideBottomNavigation: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.matched.some((route) => {
    if (route.name !== "FirstSteps") {
      const session = Vue.$cookies.get(process.env.VUE_APP_SESSION_NAME);

      if (info.getToken() !== undefined && route.meta.requiresAuth) {
        const token: string = info.getToken();
        if (jwt.isExpired(token)) {
          store.dispatch("authentication/logOut");
          info.secureLS.removeAll();
          location.reload();
        } else if (session == null) {
          store.dispatch("authentication/logOut");
          info.secureLS.removeAll();
          location.reload();
        } else {
          if (hasAccess(to.meta?.privilege)) {
            next();
          } else {
            next("/home");
          }
        }
      } else if (info.getToken() !== undefined && !route.meta.requiresAuth) {
        const token: string = info.getToken();
        if (jwt.isExpired(token)) {
          store.dispatch("authentication/logOut");
          info.secureLS.removeAll();
          location.reload();
        } else if (session == null) {
          store.dispatch("authentication/logOut");
          info.secureLS.removeAll();
          location.reload();
        } else {
          next();
        }
      } else if (info.getToken() == undefined && route.meta.requiresAuth) {
        info.secureLS.removeAll();
        next("/sign-in");
      } else {
        next();
      }
    } else {
      next();
    }
  });
});

function hasAccess(privilege?: string): boolean {
  if (!privilege) {
    return true;
  }

  const permissions: Privilege[] =
    store.getters["authentication/getPrivileges"];
  return permissions.some((value) => {
    return (
      value.name == Vue.prototype.$constants.PRIVILEGES[privilege] &&
      value.type == Vue.prototype.$constants.TYPE_OF_PRIVILEGES.R
    );
  });
}

export default router;
