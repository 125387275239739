import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getLogo() {
    return API_URL.get(`/files/logo`);
  },
  getCarousel() {
    return API_URL.get(`/files/carousel`);
  },
  getCompany() {
    return API_URL.get(`/company/`);
  },
  addEditorImage(payload: FormData) {
    return API_URL.post(`/files/editor-image`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
    });
  },
  removeEditorImage(file_name: string) {
    return API_URL.delete(`/files/editor-image/${file_name}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getInitialDataForEveryone() {
    return API_URL.get(`/system/initial-load-for-everyone`);
  },
  getInitialDataForUsers(id: string) {
    return API_URL.get(`/system/initial-load-for-users/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
