import Vue from "vue";
import Component from "vue-class-component";
import { contrast } from "chroma-js";

@Component({})
export class StyleCheck extends Vue {
  get getInputColor() {
    return this.$vuetify.theme.dark ? "white" : "black";
  }

  get getIconColor() {
    return this.$vuetify.theme.dark ? "black" : "white";
  }

  private get colors() {
    return this.$store.getters["styles/getColors"];
  }

  private checkCurrent(route: string) {
    if (route == this.$route.name) {
      return { color: "info", expand: true };
    } else {
      return { color: "black", expand: false };
    }
  }

  setColorByReference(reference: string) {
    const theme = this.$store.getters["styles/darkMode"] ? "DARK" : "LIGHT";
    const referenceColor = this.colors.find((color: any) => {
      return color.mode == theme && color.level == reference.toUpperCase();
    })?.value;

    if (referenceColor !== undefined) {
      const newColor =
        contrast("white", referenceColor) > contrast("black", referenceColor)
          ? "white"
          : "black";
      return {
        color: newColor,
        itemColor: theme == "LIGHT" ? "black" : "white",
        darkMode: newColor == "white" ? true : false,
        textClass: newColor == "white" ? "white--text" : "black--text",
      };
    } else {
      return { color: "black", itemColor: "white", darkMode: true };
    }
  }
}
