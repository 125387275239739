import { Balance } from "@/models/balance.interface";
import { Role } from "@/models/role.interface";
import { User } from "@/models/user.interface";
import UsersService from "@/services/users.service";
import Vue from "vue";
import { UsersState } from "./users.state";

export default {
  namespaced: true,
  state: {
    users: [],
    user: {},
    userBalance: {},
    accounts: {},
    configuredAccounts: false,
    sessions: [],
  },
  getters: {
    getUserSessions: (state: UsersState) => state.sessions,
    getUsers: (state: UsersState) => state.users,
    getUser: (state: UsersState) => state.user,
    getUserBalance: (state: UsersState) => state.userBalance,
    getUserAccounts: (state: UsersState) => state.accounts,
    getConfiguredAccounts: (state: UsersState) => state.configuredAccounts,
  },
  mutations: {
    setUserSessions(state: UsersState, sessions: any[]) {
      Vue.set(state, "sessions", sessions);
    },
    setUsers(state: UsersState, users: User[]) {
      Vue.set(state, "users", users);
    },
    setUser(state: UsersState, user: User) {
      Vue.set(state, "user", user);
    },
    setUserBalance(state: UsersState, balance: Balance) {
      Vue.set(state, "userBalance", balance);
    },
    setUserAccounts(state: UsersState, accounts: any) {
      Vue.set(state, "accounts", accounts);
    },
    setConfiguredAccounts(state: UsersState, configuredAccounts: boolean) {
      Vue.set(state, "configuredAccounts", configuredAccounts);
    },
    combineUserAccounts(state: any, accounts: any) {
      const listOfAccounts = state.accounts.accounts;
      accounts.accounts.forEach((account) => {
        listOfAccounts.push(account);
      });
      accounts.accounts = listOfAccounts;
      Vue.set(state, "accounts", accounts);
    },
  },
  actions: {
    getUserSessions: async (context: any) => {
      try {
        const response = (await UsersService.getUserSessions()).data;
        context.commit("setUserSessions", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    removeUserSession: async (context: any, id: string) => {
      try {
        await UsersService.removeUserSession(id);
      } catch (error) {
        throw new Error(error);
      }
    },
    getConfiguredAccounts: async (context: any) => {
      try {
        const configured = (await UsersService.getConfiguredAccounts()).data
          .configured;
        context.commit("setConfiguredAccounts", configured);
      } catch (error) {
        throw new Error(error);
      }
    },
    getStaff: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const response = (await UsersService.getStaff(payload)).data;
        context.commit("setUsers", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getClients: async (
      context: any,
      payload: { page: number; size: number; text: string }
    ) => {
      try {
        const response = (await UsersService.getClients(payload)).data;
        context.commit("setUsers", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getUserById: async (context: any, payload: number) => {
      try {
        const response = (await UsersService.getUserById(payload)).data;
        context.commit("setUser", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getUserBalanceById: async (context: any, payload: number) => {
      try {
        const response = (await UsersService.getUserBalanceById(payload))
          .data[0];
        context.commit("setUserBalance", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getUserAccounts: async (context: any, payload: any) => {
      try {
        if (payload.combine) {
          const response = (await UsersService.getUserAccounts(payload)).data;
          context.commit("combineUserAccounts", response);
        } else {
          context.commit("setUserAccounts", []);
          const response = (await UsersService.getUserAccounts(payload)).data;
          context.commit("setUserAccounts", response);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    createUWA: async (context: any, payload: any) => {
      try {
        await UsersService.createUWA(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateUWA: async (context: any, payload: any) => {
      try {
        await UsersService.updateUWA(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    clearUsers: async (context: any) => {
      try {
        context.commit("setUsers", []);
      } catch (error) {
        throw new Error(error);
      }
    },
    createStaff: async (context: any, payload: { user: User; role: Role }) => {
      try {
        await UsersService.createStaff(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateUserBlockComments: async (
      context: any,
      payload: {
        id: string;
        blockProductComments: boolean;
        blockBillComments: boolean;
      }
    ) => {
      try {
        await UsersService.updateUserBlockComments(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateUserStatus: async (
      context: any,
      payload: { id: string; status: string }
    ) => {
      try {
        await UsersService.updateUserStatus(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateUserRole: async (
      context: any,
      payload: { id: string; role: string }
    ) => {
      try {
        await UsersService.updateUserRole(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateUserEmail: async (
      context: any,
      payload: { id: string; email: string }
    ) => {
      try {
        await UsersService.updateUserEmail(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    updateUserAlias: async (
      context: any,
      payload: { id: string; alias: string }
    ) => {
      try {
        await UsersService.updateUserAlias(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    kycValidation: async (
      context: any,
      payload: { id: string; kycValidated: boolean }
    ) => {
      try {
        await UsersService.kycValidation(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    sendUserValidationLink: async (context: any, id: string) => {
      try {
        await UsersService.sendUserValidationLink(id);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
