import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getAllPaymentPlatforms(payload: {
    page: number;
    size: number;
    text: string;
  }) {
    return API_URL.get(
      `/platforms?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getPaymentPlatformById(id: number) {
    return API_URL.get(`/platforms/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  addPaymentPlatform(payment_platform: any) {
    return API_URL.post(`/platforms`, payment_platform, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  uploadPaymentPlatformLogo(payload: FormData, progress) {
    return API_URL.post(`/files/payment-platform`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  updatePaymentPlatform(payload: { data: FormData; id: number }, progress) {
    return API_URL.put(`/platforms/update/${payload.id}`, payload.data, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  updatePaymentPlatformStatus(payload: { id: number; status: string }) {
    return API_URL.put(
      `/platforms/update/status/${payload.id}`,
      {
        status: payload.status,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
