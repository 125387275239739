import Vue from "vue";
import SystemService from "@/services/system.service";
import { SystemState } from "@/store/modules/System/system.state";
import { File } from "@/models/file.interface";
import { Company } from "@/models/company.interface";

export default {
  namespaced: true,
  state: {
    logo: null,
    carousel: [],
    company: null,
  },
  getters: {
    getLogo: (state: SystemState) => state.logo,
    getCarousel: (state: SystemState) => state.carousel,
    getCompany: (state: SystemState) => state.company,
  },
  mutations: {
    setLogo(state: SystemState, logo: File) {
      Vue.set(state, "logo", logo);
    },
    setCarousel(state: SystemState, carousel: File[]) {
      Vue.set(state, "carousel", carousel);
    },
    setCompany(state: SystemState, company: Company[]) {
      Vue.set(state, "company", company);
    },
  },
  actions: {
    getSystemInfo: async (context: any) => {
      try {
        const logo = (await SystemService.getLogo()).data;
        const carousel = (await SystemService.getCarousel()).data;
        const company = (await SystemService.getCompany()).data;
        context.commit("setLogo", logo);
        context.commit("setCarousel", carousel);
        context.commit("setCompany", company);
      } catch (error) {
        throw new Error(error);
      }
    },
    getSystemInfoByInitialData: async (context: any, data: any) => {
      try {
        const logo = data.logo_file;
        const carousel = data.carousel_files;
        const company = data.company;
        context.commit("setLogo", logo);
        context.commit("setCarousel", carousel);
        context.commit("setCompany", company);
      } catch (error) {
        throw new Error(error);
      }
    },
    addEditorImage: async (context: any, payload: FormData) => {
      try {
        const image = (await SystemService.addEditorImage(payload)).data;
        return image;
      } catch (error) {
        throw new Error(error);
      }
    },
    removeEditorImage: async (context: any, file_name: string) => {
      try {
        await SystemService.removeEditorImage(file_name);
      } catch (error) {
        throw new Error(error);
      }
    },
    getInitialDataForEveryone: async () => {
      try {
        return (await SystemService.getInitialDataForEveryone()).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    getInitialDataForUsers: async (context: any, id: string) => {
      try {
        return (await SystemService.getInitialDataForUsers(id)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
