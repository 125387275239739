import { User } from "@/models/user.interface";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export class UserValidations extends Vue {
  public get userLogged(): User {
    return this.$store.getters["authentication/getUser"];
  }
}
