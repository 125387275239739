import { InformationPage } from "@/models/information-page.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  verifyFirstStepsStatus() {
    return API_URL.get(`/configuration/first-steps`);
  },
  getConfigurationPage(name: string) {
    return API_URL.get(`/configuration/${name}`);
  },
  getSecuritySession() {
    return API_URL.get(`/configuration/security-session`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateConfigurationPage(payload: InformationPage) {
    return API_URL.put(
      `/configuration/${payload.id}`,
      { configuration: { value: payload.value } },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  setFirstSteps(payload, progress) {
    return API_URL.post(`/configuration/first-steps`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  setSystemCarousel(payload, progress) {
    const query = payload.firstSteps ? "?session=destroy" : "?session=keep";
    return API_URL.post(`/files/carousel${query}`, payload.files, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  updateCompanyInfo(payload, progress) {
    return API_URL.put(`/company/${payload.id}`, payload.info, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
};
