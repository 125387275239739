import chroma, { Color, contrast } from "chroma-js";
import Vue from "vue";
import store from "@/store/index";

const MIN_CONTRAST = 4.5;

/*
color is transparent if num = 0 and hex isn't black
*/
function isTransparent(color: Color | string) {
  if (typeof color == "string") {
    color = chroma(color);
  }
  return !color.num() && !color.rgba().pop();
}

function checkBackgroundColor(element: HTMLElement, color?: string) {
  const parent = element.parentElement;
  color = color || window.getComputedStyle(element).color;

  if (parent) {
    const parentStyle = window.getComputedStyle(parent);
    const background = parentStyle.backgroundColor;
    const backgroundObject = chroma(background);

    // parent has background and it is not transparent
    if (background && !isTransparent(backgroundObject)) {
      return background;
    }
    return checkBackgroundColor(parent, color);
  }
  return null;
}

function checkThemeColor(type: string) {
  type = type.toUpperCase();
  const theme = store.getters["styles/darkMode"] ? "DARK" : "LIGHT";
  const colors: Array<{ level: string; mode: string; value: string }> =
    store.getters["styles/getColors"];
  return colors.find((color) => {
    return color.mode == theme && color.level == type;
  })?.value;
}

export function checkFromChild(element: HTMLElement, kind = "child") {
  const vm = new Vue();
  const themeColors = Object.values(vm.$constants.THEME_COLORS);
  let newColor: string | undefined;

  kind = kind.toLowerCase();
  // if kind is a color type, don't check for parent color
  if (themeColors.includes(kind)) {
    const background = checkThemeColor(kind) || checkBackgroundColor(element);
    newColor = updateContrast(element, background);
  } else {
    const checkedBackground = checkBackgroundColor(element);
    newColor = updateContrast(element, checkedBackground);
  }
  if (newColor) {
    element.style.setProperty("color", newColor, "important");
  }
}

export function checkFromParent(parentElement: Element, parentColor?: string) {
  const currentColor = window.getComputedStyle(parentElement).backgroundColor;
  const children = parentElement.children;
  /*
  if parent color doesn't exist, assign current color
  if it does exist replace it by current color if it isn't transparent
  otherwise keep parent color 
  */

  parentColor = !parentColor
    ? currentColor
    : !isTransparent(currentColor)
    ? currentColor
    : parentColor;

  for (const child of children) {
    if (child.nodeType == Node.TEXT_NODE || !child.children.length) {
      const newColor = updateContrast(child as HTMLElement, parentColor);
      if (newColor) {
        (child as HTMLElement).style.setProperty(
          "color",
          newColor,
          "important"
        );
      }
    } else {
      checkFromParent(child, parentColor);
    }
  }
}

export function updateContrast(element: HTMLElement, backgroundColor: string) {
  const elementColor = chroma(window.getComputedStyle(element).color);

  //process color if it isn't transparent
  if (!isTransparent(elementColor)) {
    const calculatedContrast = chroma.contrast(elementColor, backgroundColor);

    if (calculatedContrast < MIN_CONTRAST) {
      const newColor =
        contrast("white", backgroundColor) > contrast("black", backgroundColor)
          ? "white"
          : "black";
      return newColor;
    }
  }
}
