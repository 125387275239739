























import Vue from "vue";
import Component from "vue-class-component";
import { Notification as NClass } from "@/models/notification.interface";
import { Watch } from "vue-property-decorator";

@Component({})
export default class Notification extends Vue {
  show = false;

  private get notificationInfo(): NClass {
    return this.$store.getters["notifications/getNotification"];
  }

  private get showNotification(): boolean {
    return this.$store.getters["notifications/getShowNotification"];
  }

  private get currentPath(): string {
    return this.$route.fullPath;
  }

  private closeNotification(): void {
    this.$store.dispatch("notifications/setShowNotification", false);
  }

  @Watch("currentPath")
  private clearNotification(): void {
    if (
      !this.notificationInfo.persistent &&
      this.notificationInfo.currentPath != this.currentPath
    ) {
      this.closeNotification();
    }
  }

  @Watch("showNotification")
  private avoidCloseError(): void {
    this.show = this.showNotification;
  }
}
