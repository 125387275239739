import SecureLS from "secure-ls";
const ls = new SecureLS();

const KEYS = {
  DATA: "bt-info-" + process.env.VUE_APP_LS_KEY,
};

export default {
  secureLS: ls,
  setData: (info: any) => {
    ls.set(KEYS.DATA, JSON.stringify(info));
  },
  removeData: () => {
    ls.removeAll();
  },
  getToken: () => {
    const data = ls.get(KEYS.DATA);
    const token = JSON.parse(data !== "" ? data : "{}").token;
    return token;
  },
};
