import { Detail } from "@/models/detail.interface";
import { Investment } from "@/models/investment.interface";
import { Product } from "@/models/product.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getCreatedProductsByUser(payload: {
    page: number;
    size: number;
    user_id: string;
  }) {
    return API_URL.get(
      `/products/user/${payload.user_id}?page=${payload.page}&size=${payload.size}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getProducts(payload: {
    page: number;
    size: number;
    text: string;
    status?: string;
    category_status?: string;
  }) {
    return API_URL.get(
      `/products?page=${payload.page}&size=${payload.size}&text=${
        payload.text
      }${payload.status ? "&status=" + payload.status : ""}${
        payload.category_status
          ? "&category_status=" + payload.category_status
          : ""
      }`,
      {
        withCredentials: false,
      }
    );
  },
  getProductsByCategory(payload: {
    page: number;
    size: number;
    category_id: number;
    status?: string;
  }) {
    return API_URL.get(
      `/products/category/${payload.category_id}?page=${payload.page}&size=${
        payload.size
      } ${payload.status ? "&status=" + payload.status : ""}`,
      {
        withCredentials: false,
      }
    );
  },
  getProduct(id: string) {
    return API_URL.get(`/products/${id}`, {
      withCredentials: false,
    });
  },
  createProduct(payload: {
    product: Product;
    investment: Investment;
    details: Detail[];
    category: { id: number };
    staff: { id: string };
  }) {
    return API_URL.post(`/products`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createProductFiles(payload: FormData, progress) {
    return API_URL.post(`/files/product`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  createDetailFiles(payload: FormData, progress) {
    return API_URL.post(`/files/product/detail`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  updateProduct(payload: {
    product: Product;
    investment: Investment;
    details: Detail[];
    category: { id: number };
  }) {
    return API_URL.put(`/products/update/${payload.product.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateProductStatus(id: string, payload: string) {
    return API_URL.put(
      `/products/status/${id}`,
      { status: payload },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
