import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  signIn(payload) {
    return API_URL.post(`/auth/sign-in`, payload);
  },
  logOut() {
    return API_URL.delete(`/auth/log-out`);
  },
  getUserProfile(payload) {
    return API_URL.get(`/users/single/${payload.id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  setUserProfile(payload) {
    return API_URL.put(
      `/users/single/${payload.id}`,
      { user: payload.user },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  recoverPassword(payload) {
    return API_URL.post(`/auth/password/recover`, payload);
  },
  changePassword(payload) {
    return API_URL.put(
      `/users/password`,
      { password: payload.password },
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
          "Content-type": "application/json",
        },
        withCredentials: false,
      }
    );
  },
  changeEmail(payload) {
    return API_URL.put(
      `/users/email`,
      { email: payload.email },
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  clientSignUp(payload) {
    const query = payload.kyc ? "?kyc=true" : "?kyc=false";
    return API_URL.post(`/users/client/sign-up${query}`, payload);
  },
  clientValidate(payload) {
    return API_URL.put(
      `/users/client/validate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
          "Content-type": "application/json",
        },
        withCredentials: false,
      }
    );
  },
  staffValidate(payload) {
    return API_URL.put(`/users/staff/validate`, payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "Content-type": "application/json",
      },
      withCredentials: false,
    });
  },
  userExists(payload) {
    return API_URL.get(
      `/users/exists?email=${payload.email}&alias=${payload.alias}`
    );
  },
  validateHCaptcha(payload) {
    return API_URL.post(`/auth/hcaptcha/validate`, payload);
  },
  checkIfUserIsAvailable(id) {
    return API_URL.get(`/users/security-check/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
