var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$route.meta.simpleHeader || _vm.$route.query.header)?_c('SimpleHeader'):_vm._e(),(
      !_vm.$route.meta.hideSystemBar &&
      (!_vm.context || _vm.context == _vm.$constants.CONTEXT.FRONTOFFICE)
    )?_c('SystemBar'):_vm._e(),(
      !_vm.$route.meta.hideHeader &&
      (!_vm.context || _vm.context == _vm.$constants.CONTEXT.FRONTOFFICE)
    )?_c('Header',{on:{"openSideBar":_vm.openSideBar}}):_vm._e(),(
      !_vm.$route.meta.hideAdministratorHeader &&
      _vm.$vuetify.breakpoint.mdAndDown &&
      _vm.context &&
      _vm.context == _vm.$constants.CONTEXT.BACKOFFICE
    )?_c('AdministratorHeader',{on:{"openSideBar":_vm.openAdministratorSideBar}}):_vm._e(),(
      !_vm.$route.meta.hideSideBar &&
      (!_vm.context || _vm.context == _vm.$constants.CONTEXT.FRONTOFFICE)
    )?_c('SideBar',{attrs:{"drawer":_vm.drawer},on:{"close":_vm.openSideBar}}):_vm._e(),_c('v-main',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","fill-height":!_vm.$route.meta.checkout}},[_c('router-view')],1)],1),(
      !_vm.$route.meta.hideFooter &&
      (!_vm.context || _vm.context == _vm.$constants.CONTEXT.FRONTOFFICE)
    )?_c('Footer'):_vm._e(),(
      !_vm.$route.meta.hideAdministratorSideBar &&
      _vm.context &&
      _vm.context == _vm.$constants.CONTEXT.BACKOFFICE
    )?_c('AdministratorSideBar',{attrs:{"drawer":_vm.administratorDrawer}}):_vm._e(),(_vm.showFAB)?_c('FAB'):_vm._e(),_c('GlobalLoader',{attrs:{"loading":_vm.loading}}),_c('SystemError'),_c('LocaleSwitcher',{attrs:{"showLocaleSwitcher":false}}),_c('Notification'),_c('ConnectionStatus'),_c('ChatNotification')],1)}
var staticRenderFns = []

export { render, staticRenderFns }