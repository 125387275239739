//import RolesService from "@/services/roles.service";
import Vue from "vue";
import { KYCState } from "./kyc.state";
import KYCService from "../../../services/kyc.service";

export default {
  namespaced: true,
  state: {
    userKYC: null,
  },
  getters: {
    getUserKYC: (state: KYCState) => state.userKYC,
  },
  mutations: {
    setUserKYC(state: KYCState, userKYC: any) {
      Vue.set(state, "userKYC", userKYC);
    },
  },
  actions: {
    getUserKYC: async (context: any, user_id: string) => {
      try {
        const response = (await KYCService.getUserKYC(user_id)).data;
        context.commit("setUserKYC", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    createUserKYC: async (
      context: any,
      payload: { description: any; user_id: string }
    ) => {
      try {
        return (await KYCService.createUserKYC(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    addKYCFiles: async (
      context: any,
      payload: { files: FormData; kyc_id: any; user_id: any }
    ) => {
      try {
        await KYCService.addKYCFiles(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
