import { Role } from "@/models/role.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";
import { User } from "@/models/user.interface";

export default {
  getStaff(payload: { page: number; size: number; text: string }) {
    return API_URL.get(
      `/users/staff?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getClients(payload: {
    page: number;
    size: number;
    text: string;
    status?: string;
  }) {
    return API_URL.get(`/users/clients`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
      params: {
        ...(payload.status && { status: payload.status }),
        page: payload.page,
        size: payload.size,
        text: payload.text,
      },
    });
  },
  getUserById(id: number) {
    return API_URL.get(`/users/single/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getUserAccounts(payload: any) {
    const query =
      payload.page && payload.size
        ? `?page=${payload.page}&size=${payload.size}`
        : ``;
    const id = payload.id ? payload.id : payload;

    return API_URL.get(`/accounts/${id}${query}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getUserBalanceById(id: number) {
    return API_URL.get(`/balance/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getConfiguredAccounts() {
    return API_URL.get(`/accounts/check-configured`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createStaff(payload: { user: User; role: Role }) {
    return API_URL.post(`/users/staff/create`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createUWA(payload: any) {
    return API_URL.post(`/accounts`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateUWA(payload: any) {
    return API_URL.put(`/accounts/update/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateUserBlockComments(payload: {
    id: string;
    blockProductComments: boolean;
    blockBillComments: boolean;
  }) {
    return API_URL.put(
      `/users/commenting/${payload.id}`,
      {
        block_product_comments: payload.blockProductComments,
        block_bill_comments: payload.blockBillComments,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateUserStatus(payload: { id: string; status: string }) {
    return API_URL.put(
      `/users/status/${payload.id}`,
      {
        status: payload.status,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateUserRole(payload: { id: string; role: string }) {
    return API_URL.put(
      `/users/role/${payload.id}`,
      {
        role: payload.role,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateUserEmail(payload: { id: string; email: string }) {
    return API_URL.put(
      `/users/email/${payload.id}`,
      {
        email: payload.email,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateUserAlias(payload: { id: string; alias: string }) {
    return API_URL.put(
      `/users/alias/${payload.id}`,
      {
        alias: payload.alias,
      },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  kycValidation(payload: { id: string; kycValidated: boolean }) {
    return API_URL.put(`/users/kyc-validation/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getUserSessions() {
    return API_URL.get(`/users/sessions`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  removeUserSession(id: string) {
    return API_URL.delete(`/users/session/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  sendUserValidationLink(id: string) {
    return API_URL.get(`/users/send-validation-link/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
