import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        success: "#43A047",
        error: "#E53935",
        info: "#00ACC1",
        warning: "#FFA000",
        cta: "#039BE5",
      },
    },
  },
});
