




















import Component, { mixins } from "vue-class-component";
import { StyleCheck } from "@/mixins/style-check";
import { Prop } from "vue-property-decorator";

@Component({})
export default class SearchBar extends mixins(StyleCheck) {
  @Prop({ default: false, required: false }) black!: boolean;
  search = "";

  private async searchByText(): Promise<void> {
    this.$emit("searchProduct", this.search);
    setTimeout(() => {
      this.search = "";
    }, 1500);
  }
}
