import { Role } from "@/models/role.interface";
import { Privilege } from "@/models/privilege.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getAssignableRoles() {
    return API_URL.get(`/roles/assignable`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getRoles(payload: { page: number; size: number; text: string }) {
    return API_URL.get(
      `/roles?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getRoleById(id: number) {
    return API_URL.get(`/roles/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  getPrivileges() {
    return API_URL.get(`/privileges`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  createRole(payload: { role: Role; privileges: Privilege[] }) {
    return API_URL.post(`/roles`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  updateRole(payload: { role: Role; privileges: Privilege[] }) {
    return API_URL.put(
      `/roles/update/${payload.role.id}`,
      { role: payload.role, privileges: payload.privileges },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  updateRoleStatus(id: number, payload: string) {
    return API_URL.put(
      `/roles/status/${id}`,
      { status: payload },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
