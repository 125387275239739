import Vue from "vue";
import BillsService from "@/services/bills.service";
import { CheckoutState } from "./checkout.state";

export default {
  namespaced: true,
  state: {
    checkoutInformation: {},
    singleBill: {},
  },
  getters: {
    getCheckoutInformation: (state: CheckoutState) => state.checkoutInformation,
    getSingleBill: (state: CheckoutState) => state.singleBill,
  },
  mutations: {
    setCheckoutInformation(state: any, checkoutInformation: any) {
      Vue.set(state, "checkoutInformation", checkoutInformation);
    },
    setSingleBill(state: any, singleBill: any) {
      Vue.set(state, "singleBill", singleBill);
    },
  },
  actions: {
    setCheckoutInformation: async (context: any, payload: any) => {
      try {
        context.commit("setCheckoutInformation", payload);
        context.commit("setSingleBill", {});
      } catch (error) {
        throw new Error(error);
      }
    },
    createBill: async (
      context: any,
      payload: {
        id: string;
        quantity: number;
      }
    ) => {
      try {
        return (await BillsService.createBill(payload)).data;
      } catch (error) {
        throw new Error(error);
      }
    },
    getSingleBill: async (context: any, payload: string) => {
      try {
        const bill = (await BillsService.getSingleBill(payload)).data;
        context.commit("setSingleBill", bill);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
