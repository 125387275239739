import Vue from "vue";
import { Penalty, PenaltyUser } from "@/models/penalty.interface";
import penaltiesService from "@/services/penalties.service";
import { PenaltiesState } from "./penalties.state";
import usersService from "@/services/users.service";
import { User } from "@/models/user.interface";
export default {
  namespaced: true,
  state: {
    penalties: [],
    penaltyTypes: [],
    clientsForPenalties: [],
    penalty: {},
  },
  getters: {
    getPenalties: (state: PenaltiesState) => state.penalties,
    getPenaltyTypes: (state: PenaltiesState) => state.penaltyTypes,
    getClientsForPenalties: (state: PenaltiesState) =>
      state.clientsForPenalties,
    getPenalty: (state: PenaltiesState) => state.penalty,
  },
  mutations: {
    setPenalties(state: PenaltiesState, penalties: PenaltyUser[]) {
      Vue.set(state, "penalties", penalties);
    },
    combinePenalties(
      state: PenaltiesState,
      penalties: { penalties: PenaltyUser[] }
    ) {
      const penaltiesState = state.penalties;
      penalties.penalties.forEach((penalty) => {
        penaltiesState.penalties.push(penalty);
      });
      penalties.penalties = penaltiesState.penalties;
      Vue.set(state, "penalties", penalties);
    },
    setPenaltyTypes(
      state: PenaltiesState,
      penalties: { penalties: Penalty[] }
    ) {
      Vue.set(state, "penaltyTypes", penalties.penalties);
    },
    setPenalty(state: PenaltiesState, penalty: PenaltyUser) {
      Vue.set(state, "penalty", penalty);
    },
    setClientsForPenalties(state: PenaltiesState, clients: { users: User[] }) {
      Vue.set(state, "clientsForPenalties", clients.users);
    },
  },
  actions: {
    getClientPenalties: async (
      context: any,
      payload: {
        page: number;
        size: number;
        client: string;
        status?: string;
        combine?: boolean;
      }
    ) => {
      try {
        const response = (await penaltiesService.getPenaltiesByClient(payload))
          .data;
        if (payload.combine) {
          context.commit("combinePenalties", response);
        } else {
          context.commit("setPenalties", response);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getPenalties: async (
      context: any,
      payload: {
        page: number;
        size: number;
        text?: string;
        status?: string;
        combine?: boolean;
      }
    ) => {
      try {
        const response = (await penaltiesService.getAllPenalties(payload)).data;
        if (payload.combine) {
          context.commit("combinePenalties", response);
        } else {
          context.commit("setPenalties", response);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getPenalty: async (context: any, payload: string) => {
      try {
        const response = (await penaltiesService.getSinglePenalty(payload))
          .data;
        context.commit("setPenalty", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    getPenaltyTypes: async (context: any) => {
      try {
        const response = (await penaltiesService.getPenaltyTypes()).data;
        context.commit("setPenaltyTypes", response);
      } catch (error) {
        throw new Error(error);
      }
    },
    createPenalty: async (context: any, payload: PenaltyUser) => {
      try {
        await penaltiesService.createPenalty({
          ...payload,
          client: payload.fk_client,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    updatePenalty: async (context: any, payload: PenaltyUser) => {
      try {
        await penaltiesService.updatePenalty(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    getClientsForPenalties: async (context: any, payload: any) => {
      try {
        const response = (
          await usersService.getClients({ page: 1, size: 20, ...payload })
        ).data;
        context.commit("setClientsForPenalties", response);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
