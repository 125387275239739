import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getSystemSummary() {
    return API_URL.get(`/summary/system`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
};
