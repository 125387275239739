import Vue from "vue";
import { NotificationsState } from "@/store/modules/Notifications/notifications.state";
import { SystemError } from "@/models/system-error.interface";
import { Notification } from "@/models/notification.interface";

export default {
  namespaced: true,
  state: {
    error: {},
    notification: {},
    showError: false,
    showNotification: false,
    chatOpened: false,
  },
  getters: {
    getSystemError: (state: NotificationsState) => state.error,
    getShowError: (state: NotificationsState) => state.showError,
    getNotification: (state: NotificationsState) => state.notification,
    getShowNotification: (state: NotificationsState) => state.showNotification,
    getChatOpened: (state: NotificationsState) => state.chatOpened,
  },
  mutations: {
    setSystemError(state: NotificationsState, error: SystemError) {
      Vue.set(state, "error", error);
    },
    setShowError(state: NotificationsState, showError: boolean) {
      Vue.set(state, "showError", showError);
    },
    setNotification(state: NotificationsState, notification: Notification) {
      Vue.set(state, "notification", notification);
    },
    setShowNotification(state: NotificationsState, showNotification: boolean) {
      Vue.set(state, "showNotification", showNotification);
    },
    setChatOpened(state: NotificationsState, chatOpened: boolean) {
      Vue.set(state, "chatOpened", chatOpened);
    },
  },
  actions: {
    showSystemError: async (context: any, payload: SystemError) => {
      context.commit("setSystemError", payload);
      context.commit("setShowError", true);
    },
    setShowError: async (context: any, payload: boolean) => {
      context.commit("setShowError", payload);
    },
    showNotification: async (context: any, payload: Notification) => {
      context.commit("setNotification", payload);
      context.commit("setShowNotification", true);
    },
    setShowNotification: async (context: any, payload: boolean) => {
      context.commit("setShowNotification", payload);
    },
    setChatOpened: async (context: any, payload: boolean) => {
      context.commit("setChatOpened", payload);
    },
  },
};
