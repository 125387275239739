import Vue from "vue";
import WishlistService from "@/services/wishlist.service";
import { WishlistState } from "./wishlist.state";
import { Wishlist } from "@/models/wishlist.interface";

export default {
  namespaced: true,
  state: {
    wishlist: [],
    wishlistToVerify: [],
  },
  getters: {
    getWishlistByUser: (state: WishlistState) => state.wishlist,
    getWishlistByUserToVerify: (state: WishlistState) => state.wishlistToVerify,
  },
  mutations: {
    setWishlistByUser(state: WishlistState, wishlist: Wishlist[]) {
      Vue.set(state, "wishlist", wishlist);
    },
    combineWishlistByUser(state: WishlistState, wishlist: any) {
      const listOfWishlist = state.wishlist.wishlist;
      wishlist.wishlist.forEach((wishlist: Wishlist) => {
        listOfWishlist.push(wishlist);
      });
      wishlist.wishlist = listOfWishlist;
      Vue.set(state, "wishlist", wishlist);
    },
    setWishlistByUserToVerify(
      state: WishlistState,
      wishlistToVerify: Wishlist[]
    ) {
      Vue.set(state, "wishlistToVerify", wishlistToVerify);
    },
  },
  actions: {
    getWishlistByUser: async (
      context: any,
      payload: { page: number; size: number; user_id: string; combine: boolean }
    ) => {
      try {
        const wishlist = (await WishlistService.getWishlistByUser(payload))
          .data;

        if (payload.combine) {
          context.commit("combineWishlistByUser", wishlist);
        } else {
          context.commit("setWishlistByUser", wishlist);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    getWishlistByUserToVerify: async (context: any, payload: string) => {
      try {
        const wishlist = (
          await WishlistService.getWishlistToVerifyAProduct(payload)
        ).data;
        context.commit("setWishlistByUserToVerify", wishlist);
      } catch (error) {
        throw new Error(error);
      }
    },
    addProductToWishlist: async (context: any, payload: string) => {
      try {
        await WishlistService.addProductToWishlist(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
    removeProductFromWishlist: async (context: any, payload: number) => {
      try {
        await WishlistService.removeProductFromWishlist(payload);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
