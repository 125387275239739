





























import { Navigation } from "@/mixins/navigation";
import { StyleCheck } from "@/mixins/style-check";
import Component, { mixins } from "vue-class-component";

@Component({})
export default class FAB extends mixins(Navigation, StyleCheck) {
  private async changeContext() {
    if (this.context == this.$constants.CONTEXT.BACKOFFICE) {
      await this.$store.dispatch(
        "authentication/setContext",
        this.$constants.CONTEXT.FRONTOFFICE
      );
      this.navigate("/home");
    } else {
      await this.$store.dispatch(
        "authentication/setContext",
        this.$constants.CONTEXT.BACKOFFICE
      );
      this.navigate("/staff/home");
    }
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }

  private get checkContext() {
    if (this.context == this.$constants.CONTEXT.BACKOFFICE) {
      return true;
    } else {
      return false;
    }
  }
}
