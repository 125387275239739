import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getBillComments(bill_id: string, size = 50) {
    return API_URL.get(`/comments/bill/${bill_id}?size=${size}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  sendBillComment(
    payload: { text: string; bill_id: string; onlyText: boolean },
    progress
  ) {
    return API_URL.post(`/comments/bill`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
      onUploadProgress: progress,
    });
  },
  updateBillComments(payload: {
    bill_id: string;
    comments: string[];
    status: string;
  }) {
    return API_URL.put(`/comments/bill`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  uploadBillCommentFile(payload: FormData, progress) {
    return API_URL.post(`/files/bill/comment`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
};
