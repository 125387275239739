import Vue from "vue";
import { LanguageState } from "@/store/modules/Language/language.state";

export default {
  namespaced: true,
  state: {
    language: "en",
  },
  getters: {
    getLanguage: (state: LanguageState) => state.language,
  },
  mutations: {
    setLanguage(state: LanguageState, language: string) {
      Vue.set(state, "language", language);
    },
  },
  actions: {
    setLanguage: async (context: any, payload: string) => {
      context.commit("setLanguage", payload);
    },
  },
};
