import { Newsletter } from "@/models/newsletter.interface";
import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  subscribe(payload: Newsletter) {
    return API_URL.post(`/newsletter/subscribe`, payload);
  },
  getNewsletter(payload: { page: number; size: number; text: string }) {
    return API_URL.get(
      `/newsletter?page=${payload.page}&size=${payload.size}&text=${payload.text}`,
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
  getAssignableSubscriptors() {
    return API_URL.get(`/newsletter/assignable`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  sendCampaign(payload, progress) {
    return API_URL.post(`/newsletter/campaign`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  removeSubscriber(id: number) {
    return API_URL.delete(`/newsletter/subscriber/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "application/json",
      },
    });
  },
  unsubscribe(payload: any) {
    return API_URL.delete(`/newsletter/unsubscribe/${payload.email}`, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "Content-type": "application/json",
      },
    });
  },
};
