import { API_URL } from "@/services/service.config";
import info from "@/utils/info";

export default {
  getCategories() {
    return API_URL.get(`/categories`);
  },
  getAssignableCategories() {
    return API_URL.get(`/categories/assignable`);
  },
  createCategory(payload: FormData, progress) {
    return API_URL.post(`/categories`, payload, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  updateCategory(payload: { data: FormData; id: number }, progress) {
    return API_URL.put(`/categories/update/${payload.id}`, payload.data, {
      headers: {
        Authorization: `Bearer ${info.getToken()}`,
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: progress,
    });
  },
  updateCategoryStatus(id: number, payload: string) {
    return API_URL.put(
      `/categories/status/${id}`,
      { status: payload },
      {
        headers: {
          Authorization: `Bearer ${info.getToken()}`,
          "Content-type": "application/json",
        },
      }
    );
  },
};
